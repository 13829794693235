import {
  mockNotifications,
  mockNotificationsFilters,
} from '@/__mocks__/notifications';
import { Jurisdiction, Regulation, Regulator } from '@/types';
import { SortParams } from '@/types/api';
import { NotificationResult } from '@/types/notification';
import api from './index';

export interface GetNotificationsResponse {
  notifications: NotificationResult[];
  total: number;
}

export interface GetFilterOptionsResponse {
  jurisdictions: Jurisdiction[];
  regulators: Regulator[];
  regulations: Regulation[];
  documentTypes: string[];
  topics: string[];
}

export type SortField = 'date' | 'risk';

export type NotificationsRequest = {
  profileIds: string[];
  dateRange?: [string, string];
  sort_params?: SortParams<SortField>;
  keywords?: string[];
  documentType?: string[];
  regulator?: Regulator[];
  regulation?: Regulation[];
  jurisdiction?: Jurisdiction[];
  topic?: string[];
};

export type GetNotificationsParams = {
  page: number;
  pageSize: number;
} & NotificationsRequest;

export const getNotifications = async ({
  profileIds,
  page,
  pageSize,
  dateRange,
  sort_params,
  keywords,
  documentType,
  regulator,
  regulation,
  jurisdiction,
  topic,
}: GetNotificationsParams): Promise<GetNotificationsResponse> => {
  if (process.env.NODE_ENV === 'development') {
    return {
      notifications: await mockNotifications(),
      total: (await mockNotifications()).length,
    };
  }
  const request: NotificationsRequest = {
    profileIds: profileIds,
    dateRange: dateRange,
    sort_params: sort_params,
    keywords: keywords,
    documentType: documentType,
    regulator: regulator,
    regulation: regulation,
    jurisdiction: jurisdiction,
    topic: topic,
  };

  const response = await api.post(
    `notifications?page=${page}&page_size=${pageSize}`,
    request,
  );
  return response.data;
};

export const getNotificationById = async (
  id: string,
): Promise<NotificationResult> => {
  if (process.env.NODE_ENV === 'development') {
    return (await mockNotifications())[0];
  }
  const response = await api.get(`notifications/${id}`);
  return response.data;
};

export async function getFilterOptions(
  profileIds: string[],
): Promise<GetFilterOptionsResponse> {
  if (process.env.NODE_ENV === 'development') {
    return mockNotificationsFilters();
  }
  const response = await api.post('notifications/filters', {
    profile_ids: profileIds,
  });
  return response.data;
}
