import Pill from '@/components/Buttons/Pill';
import ProfileSection from '@/components/Profiles/Shared/ProfileSection';
import BaseTooltip from '@/components/Utils/BaseTooltip';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { KeyReturn } from '@phosphor-icons/react';
import { useState } from 'react';

interface Props {
  title: string;
  description: string;
  items: string[];
  onChange: (newItems: string[]) => void;
  icon: React.ElementType;
}

const ProfileInputSection: React.FC<Props> = ({
  title,
  description,
  items,
  onChange,
  icon,
}: Props) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (inputValue.trim()) {
        onChange([...items.filter(Boolean), inputValue.trim()]);
        setInputValue('');
      }
    }
  };

  const handleRemoveItem = (indexToRemove: number) => {
    onChange(items.filter((_, index) => index !== indexToRemove));
  };

  const handleAddItem = () => {
    if (inputValue.trim()) {
      onChange([...items.filter(Boolean), inputValue.trim()]);
      setInputValue('');
    }
  };

  return (
    <ProfileSection icon={icon} title={title} description={description}>
      <div className="space-y-4">
        <Transition
          show={items.some(Boolean)}
          enter="transition-all duration-300 ease-out"
          enterFrom="transform -translate-y-4 opacity-0"
          enterTo="transform translate-y-0 opacity-100"
          leave="transition-all duration-200 ease-in"
          leaveFrom="transform translate-y-0 opacity-100"
          leaveTo="transform -translate-y-4 opacity-0"
        >
          <div className="flex flex-wrap gap-2">
            {items.filter(Boolean).map((item, idx) => (
              <Pill
                variant="solid"
                size="sm"
                startIcon={<XMarkIcon className="h-4 w-4" />}
                key={idx}
                text={item}
                onClick={() => handleRemoveItem(idx)}
              />
            ))}
          </div>
        </Transition>

        <div className="relative">
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleInputKeyDown}
            maxLength={50}
            className="block h-full w-full select-none appearance-none rounded-md border border-gray-200 bg-white py-3 pl-4 pr-20 text-sm text-gray-900 shadow-sm transition-all duration-200 placeholder:text-gray-400 hover:border-gray-300 hover:bg-gray-50 focus:border-gray-200 focus:bg-gray-50 focus:outline-none focus:ring-0"
            placeholder={`Add a ${title.slice(0, -1).toLowerCase()}`}
            style={{
              outline: 'none',
              borderColor: 'transparent !important',
            }}
          />
          <div className="absolute right-2 top-1/2 flex -translate-y-1/2 items-center gap-2">
            {inputValue.trim() !== '' && (
              <>
                <BaseTooltip tooltipContent="Press Enter to submit">
                  <button
                    type="button"
                    onClick={handleAddItem}
                    className="text-xs text-gray-500"
                  >
                    <KeyReturn size={16} />
                  </button>
                </BaseTooltip>
                <BaseTooltip tooltipContent="Clear input">
                  <button
                    type="button"
                    onClick={() => setInputValue('')}
                    className="group rounded-md p-2 transition-all duration-200 hover:bg-gray-100"
                  >
                    <XMarkIcon className="h-5 w-5 text-gray-500 transition-all duration-200 group-hover:text-gray-700" />
                  </button>
                </BaseTooltip>
              </>
            )}
          </div>
        </div>
      </div>
    </ProfileSection>
  );
};

export default ProfileInputSection;
