import ProfileHome from '@/components/Profiles/ProfileHome';
import usePageVisitTracker from '@/hooks/tracking/usePageVisitTracker';
import NavLayout from '@/layouts/Navigation/NavLayout';
import { useProfilesContext } from '@/providers/profilesProvider';

const ProfilePage = () => {
  usePageVisitTracker('Profile');
  const { profiles } = useProfilesContext();

  return (
    <NavLayout>
      <div className="flex h-full w-full">
        <ProfileHome profiles={profiles || []} />
      </div>
    </NavLayout>
  );
};

export default ProfilePage;
