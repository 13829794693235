import backgroundImage from '@/assets/images/blue_spots_bg.png';
import LogoWithText from '@/components/Brand/LogoWithText';
import Button from '@/components/Buttons/Button';
import Spinner from '@/components/Utils/Spinner';
import { navigationMap } from '@/consts/navigation';
import useAuth from '@/hooks/useAuth';
import { LogoSize, LogoVariant } from '@/types';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const BOOK_DEMO_URL = 'https://www.regsage.com/book-a-demo';

function StyledInput({
  title,
  type,
  id,
  name,
  autoComplete,
  placeholder,
  required,
  errorMessage,
  defaultValue,
  onChange,
  className,
}: any) {
  return (
    <div className="mb-4">
      <label htmlFor={id} className="block text-sm font-medium text-slate-700">
        {title}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        autoComplete={autoComplete}
        placeholder={placeholder}
        required={required}
        defaultValue={defaultValue}
        onChange={onChange}
        className={`mt-1 block w-full rounded-md border-slate-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 ${className}`}
      />
      {errorMessage && (
        <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
      )}
    </div>
  );
}

function ErrorDisplay({ message }: { message: string }) {
  return (
    <div
      className={`mt-4 rounded-md bg-red-100 p-4 text-sm text-red-700 transition-opacity duration-300 ${
        message ? 'opacity-100' : 'opacity-0'
      }`}
    >
      {message}
    </div>
  );
}

type SignInState = {
  email?: string;
  password?: string;
};

export default function LoginPage() {
  const navigate = useNavigate();
  const { getCurrentUser, signIn } = useAuth();

  const [formState, setFormState] = useState<SignInState>({
    email: '',
    password: '',
  });

  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Check if user is already logged in
  useEffect(() => {
    async function checkUser() {
      const user = await getCurrentUser();
      if (user) {
        // TODO: this blocks directly navigating to any other page
        navigate(navigationMap.radar.href);
      }
    }
    checkUser();
  }, [getCurrentUser, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);

    if (!formState.email || !formState.password) {
      setError('Email and password are required.');
      setLoading(false);
      return;
    }

    const res = await signIn({
      username: formState.email,
      password: formState.password,
    });

    setLoading(false);

    if (res?.isSignedIn) {
      // Track login event
      posthog.capture('user_login', {
        distinct_id: formState.email,
      });

      // force reload to get the new user
      window.location.href = navigationMap.radar.href;
      return;
    }
    if (res?.error) {
      setError(res.error);
    }
  };

  return (
    <main className="flex min-h-screen">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:w-3/5 lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <LogoWithText variant={LogoVariant.LIGHT} size={LogoSize.MEDIUM} />
            <h2 className="mt-8 text-2xl/9 font-semibold text-slate-900">
              Sign in to your account
            </h2>
          </div>

          <div className="mt-10">
            <form onSubmit={handleSubmit} className="space-y-6">
              <StyledInput
                title="Email"
                type="email"
                id="email"
                name="email"
                autoComplete="email"
                placeholder="Email address"
                required
                defaultValue={formState?.email}
                onChange={handleInputChange}
              />

              <div className="relative">
                <StyledInput
                  title="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  placeholder="Password"
                  required
                  defaultValue={formState?.password}
                  onChange={handleInputChange}
                  className="pr-10"
                />
                <span
                  onClick={togglePasswordVisibility}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      togglePasswordVisibility();
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  className="absolute right-3 top-1/2 mt-2.5 -translate-y-1/2 transform cursor-pointer text-slate-500"
                  aria-label={showPassword ? 'Hide password' : 'Show password'}
                >
                  {showPassword ? <EyeSlash size={20} /> : <Eye size={20} />}
                </span>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-slate-300 text-blue-600 focus:ring-blue-600"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-3 block text-sm/6 text-slate-700"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm/6">
                  <a
                    href={navigationMap.forgotPassword.href}
                    className="font-medium text-blue-600 hover:text-blue-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>

              <div>
                <Button
                  type="submit"
                  className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm/6 font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ${!formState?.email || !formState?.password ? 'cursor-not-allowed' : ''}`}
                  disabled={
                    !formState?.email || !formState?.password || loading
                  }
                >
                  {loading ? (
                    <Spinner size="sm" className="text-white" />
                  ) : (
                    'Sign in'
                  )}
                </Button>
              </div>
            </form>

            <p className="mt-4 text-sm/6 text-slate-500">
              Don&apos;t have an account?{' '}
              <Link
                to={navigationMap.register.href}
                className="px-1 font-medium text-blue-600 hover:text-blue-500"
              >
                Sign up
              </Link>
            </p>

            <p className="mt-4 text-sm/6 text-slate-500">
              Want to see a demo?{' '}
              <a
                href={BOOK_DEMO_URL}
                target="_blank"
                rel="noreferrer"
                className="px-1 font-medium text-blue-600 hover:text-blue-500"
              >
                Book a demo
              </a>
            </p>

            {error && <ErrorDisplay message={error} />}
          </div>
        </div>
      </div>
      <div className="relative hidden lg:block lg:w-2/5">
        <img
          alt="Login background"
          src={backgroundImage}
          className="absolute inset-0 h-full w-full object-cover"
        />
      </div>
    </main>
  );
}
