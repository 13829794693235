// src/components/Profiles/shared/ProfileSection.tsx
interface Props {
  icon: React.ElementType;
  title: string;
  description?: string;
  children: React.ReactNode;
}

const ProfileSection: React.FC<Props> = ({
  icon: Icon,
  title,
  description,
  children,
}: Props) => (
  <div className="mt-8 border-b border-gray-900/10 pb-12">
    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-2">
      <div className="flex">
        <div className="mr-3">
          <span className="inline-flex rounded-lg bg-gray-100 p-2 text-gray-700 ring-4 ring-white">
            <Icon className="h-[18px] w-[18px]" />
          </span>
        </div>
        <div>
          <h3 className="text-base font-medium text-gray-900">{title}</h3>
          {description && (
            <p className="mt-3 text-sm/6 text-gray-600">{description}</p>
          )}
        </div>
      </div>
      <div>{children}</div>
    </div>
  </div>
);

export default ProfileSection;
