import { FilterKey } from '@/types/filter';
import React from 'react';
interface SearchGroupProps<T> {
  groupId: FilterKey;
  title: string;
  items: T[];
  renderItem: (item: T, onSelect: (item: T) => void) => React.ReactNode;
  maxItems?: number;
  onSelect: (item: T, groupId: FilterKey) => void;
}

const SearchGroup = <T,>({
  groupId,
  title,
  items,
  renderItem,
  maxItems = 5,
  onSelect,
}: SearchGroupProps<T>) => {
  if (items.length === 0) return null;

  return (
    <div className="flex w-full flex-col">
      {title && (
        <h2 className="mb-2 px-3 text-xs font-normal text-gray-600">
          {title}
        </h2>
      )}
      <ul className="flex w-full flex-col space-y-1">
        {items
          .slice(0, maxItems)
          .map((item) => renderItem(item, (item) => onSelect(item, groupId)))}
      </ul>
    </div>
  );
};

export default SearchGroup;
