import SearchGroup from '@/components/Search/SearchPallete/SearchGroup';
import SearchInput from '@/components/Search/SearchPallete/SearchInput';
import SearchItem from '@/components/Search/SearchPallete/SearchItem';
import useFilters from '@/hooks/useFilters';
import { countryCodeToName } from '@/lib/utils';
import { useSearchFiltersContext } from '@/providers/searchFiltersProvider';
import { useSearchContext } from '@/providers/searchProvider';
import { Jurisdiction, Regulation, Regulator } from '@/types';
import { FilterEnum, FilterKey, FilterOption } from '@/types/filter';
import { Fragment, useEffect, useRef, useState } from 'react';

const MAX_ITEMS_IN_SEARCH_GROUP = 5;

const SearchPalette: React.FC = () => {
  const [open, setOpen] = useState(false);
  const searchElement = useRef<HTMLDivElement>(null);
  const { filters, setFilters } = useSearchFiltersContext();
  const { query, setQuery } = useSearchContext();
  const { handleSelect } = useFilters({ setFilters });

  const regulations =
    filters[FilterEnum.REGULATION]?.map((option) =>
      JSON.parse(option.value),
    ) || [];
  const authorities =
    filters[FilterEnum.AUTHORITY]?.map((option) => JSON.parse(option.value)) ||
    [];
  const jurisdictions =
    filters[FilterEnum.JURISDICTION]?.map((option) =>
      JSON.parse(option.value),
    ) || [];

  const shuffleArray = <T,>(array: T[]): T[] => {
    return array.sort(() => Math.random() - 0.5);
  };

  const filteredRegulations = shuffleArray(
    regulations.filter((reg) =>
      reg.normalizedLongName?.toLowerCase().includes(query.toLowerCase()),
    ),
  );

  const filteredAuthorities = shuffleArray(
    authorities.filter((auth) =>
      auth.normalizedLongName?.toLowerCase().includes(query.toLowerCase()),
    ),
  );

  const filteredJurisdictions = shuffleArray(
    jurisdictions.filter((jur) => {
      const countryName = countryCodeToName(jur?.country);
      const regionName = jur?.region;
      return (
        (countryName &&
          countryName.toLowerCase().includes(query.toLowerCase())) ||
        (regionName &&
          regionName.toLowerCase().includes(query.toLowerCase())) ||
        false
      );
    }),
  );

  const isFilteredItemsEmpty = () => {
    return (
      filteredRegulations.length === 0 &&
      filteredAuthorities.length === 0 &&
      filteredJurisdictions.length === 0
    );
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchElement.current &&
        !searchElement.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handler to update selected filters
  const onSelectHandler = (
    item: Regulation | Regulator | Jurisdiction,
    filterType: FilterKey,
  ) => {
    const label =
      filterType === FilterEnum.JURISDICTION
        ? (item as Jurisdiction).country || (item as Jurisdiction).region
        : (item as Regulation | Regulator).normalizedLongName;

    const filterOption: FilterOption = {
      value: JSON.stringify(item),
      label: label || '',
    };

    handleSelect(filterType, filterOption);
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <div
        ref={searchElement}
        className="relative min-w-[640px] md:min-w-[720px]"
      >
        <SearchInput query={query} setQuery={setQuery} setOpen={setOpen} />

        {open && !isFilteredItemsEmpty() && (
          <div className="absolute top-full z-20 w-full max-w-none divide-y divide-gray-100 rounded-lg bg-white p-4 shadow-xl transition-all duration-200">
            <div className="w-full space-y-4">
              {[
                {
                  id: FilterEnum.REGULATION,
                  title: 'Regulations',
                  items: filteredRegulations,
                  getField: (item: Regulation) => item.normalizedLongName,
                },
                {
                  id: FilterEnum.AUTHORITY,
                  title: 'Authorities',
                  items: filteredAuthorities,
                  getField: (item: Regulator) => item.normalizedLongName,
                },
                {
                  id: FilterEnum.JURISDICTION,
                  title: 'Jurisdictions',
                  items: filteredJurisdictions,
                  getField: (item: Jurisdiction) =>
                    countryCodeToName(item.country || '') ||
                    item.region ||
                    item.state,
                },
              ].map((group, index, array) => (
                <Fragment key={group.title}>
                  {group.items.length > 0 && (
                    <SearchGroup<Regulation | Regulator | Jurisdiction>
                      groupId={group.id}
                      title={group.title}
                      items={group.items}
                      renderItem={(item, onSelect) => (
                        <SearchItem
                          key={group.getField(item)}
                          name={group.getField(item) as string}
                          onClick={() => {
                            onSelectHandler(item, group.id);
                          }}
                        />
                      )}
                      maxItems={MAX_ITEMS_IN_SEARCH_GROUP}
                      onSelect={onSelectHandler}
                    />
                  )}
                  {group.items.length > 0 && index < array.length - 1 && (
                    <div className="my-2 border-t border-gray-200"></div>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPalette;
