import {
  getNotificationById,
  getNotifications,
  GetNotificationsResponse,
} from '@/api/notifications';
import { Jurisdiction, Regulation, Regulator } from '@/types';
import { SortParams } from '@/types/api';
import {
  NotificationResult,
  SortFieldNotification,
} from '@/types/notification';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const NOTIFICATION_QUERY_KEY = 'NOTIFICATIONS';

export function useNotifications({
  page,
  pageSize,
  profileIds,
  jurisdiction,
  regulator,
  regulation,
  documentType,
  topic,
  startDate,
  endDate,
  sortParams,
  searchFilter,
  notificationId,
}: {
  page: number;
  pageSize: number;
  profileIds: string[];
  jurisdiction: Jurisdiction[];
  regulator: Regulator[];
  regulation: Regulation[];
  documentType: string[];
  topic: string[];
  startDate: Date;
  endDate: Date;
  sortParams: SortParams<SortFieldNotification>;
  searchFilter?: string;
  notificationId?: string;
}) {
  const {
    data: notificationsData,
    error: notificationsError,
    isFetching: isFetchingNotifications,
  } = useQuery({
    queryKey: [
      NOTIFICATION_QUERY_KEY,
      profileIds,
      page,
      pageSize,
      jurisdiction,
      regulator,
      regulation,
      documentType,
      topic,
      startDate,
      endDate,
      sortParams,
      searchFilter,
    ],
    queryFn: async (): Promise<GetNotificationsResponse> => {
      if (!profileIds.length) {
        return { notifications: [], total: 0 };
      }
      const res = await getNotifications({
        profileIds,
        page,
        pageSize,
        dateRange: [startDate.toISOString(), endDate.toISOString()],
        sort_params: sortParams,
        keywords: searchFilter
          ? searchFilter.split(' ').filter((word) => word.length > 0)
          : [],
        jurisdiction,
        regulator,
        regulation,
        documentType,
        topic,
      });
      return res;
    },
    refetchOnWindowFocus: false,
  });

  const notifications = useMemo(
    () => notificationsData?.notifications,
    [notificationsData],
  );

  const {
    data: notificationByIdData,
    error: notificationByIdError,
    isLoading: isFetchingNotificationById,
  } = useQuery<NotificationResult, Error>({
    queryKey: ['notification', notificationId],
    queryFn: () => getNotificationById(notificationId || ''),
    enabled: !!notificationId,
    refetchOnWindowFocus: false,
  });

  return {
    isFetchingNotifications,
    notificationsError,
    notifications,
    totalCount: notificationsData?.total,
    totalPages: notificationsData?.total
      ? Math.ceil(notificationsData.total / pageSize)
      : 0,
    notificationByIdData,
    notificationByIdError,
    isFetchingNotificationById,
  };
}
