import { FilterKey, FilterOption } from '@/types/filter';

const useFilters = ({
  setFilters,
}: {
  setFilters: React.Dispatch<
    React.SetStateAction<Record<FilterKey, FilterOption[]>>
  >;
}) => {
  const handleSelect = (filterType: FilterKey, filterOption: FilterOption) => {
    setFilters((prevFilters) => {
      const currentFilter = prevFilters[filterType] || [];
      return {
        ...prevFilters,
        [filterType]: currentFilter.map((filter) =>
          filter.value === filterOption.value
            ? { ...filter, checked: !filter.checked }
            : filter,
        ),
      };
    });
  };

  const handleSelectAll = (filterType: FilterKey) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].map((filter) => ({
        ...filter,
        checked: true,
      })),
    }));
  };

  const handleDeselect = (
    filterType: FilterKey,
    filterOption: FilterOption,
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].map((filter) =>
        filter.value === filterOption.value
          ? { ...filter, checked: false }
          : filter,
      ),
    }));
  };

  const handleDeselectAll = (filterType: FilterKey) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: prevFilters[filterType].map((filter) => ({
        ...filter,
        checked: false,
      })),
    }));
  };

  return {
    handleSelect,
    handleSelectAll,
    handleDeselect,
    handleDeselectAll,
  };
};

export default useFilters;
