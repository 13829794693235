import classNames from 'classnames';

type PillSize = 'xs' | 'sm' | 'md' | 'lg';
type PillVariant = 'solid' | 'outline';
type PillColor = 'gray' | 'black' | 'blue';

interface PillProps {
  text: string;
  startIcon?: React.ReactNode;
  onClick?: () => void;
  variant?: PillVariant;
  size?: PillSize;
  color?: PillColor;
}

const Pill: React.FC<PillProps> = ({
  text,
  startIcon,
  onClick,
  variant = 'solid',
  size = 'md',
  color = 'gray',
}: PillProps) => {
  const sizeClasses = {
    xs: 'px-2 py-1 text-xs',
    sm: 'px-2.5 py-1.5 text-sm',
    md: 'px-3 py-2 text-sm',
    lg: 'px-4 py-2 text-base',
  };

  const variantClasses = {
    solid: {
      gray: 'bg-gray-50 text-gray-700 ring-1 ring-inset ring-gray-700/10',
      black: 'bg-gray-900 text-white ring-1 ring-inset ring-gray-900/10',
      blue: 'bg-blue-50 text-blue-700 ring-1 ring-inset ring-blue-700/10',
    },
    outline: {
      gray: 'bg-white text-gray-700 ring-1 ring-inset ring-gray-300',
      black: 'bg-white text-gray-900 ring-1 ring-inset ring-gray-900',
      blue: 'bg-white text-blue-700 ring-1 ring-inset ring-blue-500',
    },
  };

  const pillClasses = classNames(
    'inline-flex items-center rounded-full font-medium',
    sizeClasses[size],
    variantClasses[variant][color],
  );

  return (
    <span className={pillClasses}>
      <button
        type="button"
        onClick={onClick}
        className={classNames('flex items-center', {
          'mr-2': startIcon,
          'mx-1': !startIcon,
        })}
      >
        {startIcon && <span className="mr-2">{startIcon}</span>}
        {text}
      </button>
    </span>
  );
};

export default Pill;
