import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { memo } from 'react';

const NoSearchResultsDisplay = memo(() => (
  <div className="flex h-full w-full flex-col items-center justify-center px-4 py-16 text-center text-gray-500">
    <MagnifyingGlassIcon className="mb-4 h-12 w-12 text-gray-400" />
    <p className="text-lg font-medium">No results found</p>
  </div>
));
NoSearchResultsDisplay.displayName = 'NoSearchResultsDisplay';

export default NoSearchResultsDisplay;
