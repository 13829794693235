import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import millify from 'millify';

interface PaginationProps {
  currentPage: number;
  pageSize: number;
  padding: number;
  goToPage: (page: number) => void;
  totalPages?: number;
  totalResults?: number;
  showEstimatedResults?: boolean;
}

const Pagination = ({
  totalPages,
  currentPage,
  pageSize,
  totalResults,
  padding,
  goToPage,
}: PaginationProps) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  // Calculate the range of pages to display based on padding
  const startPage = Math.max(1, currentPage - padding);
  const endPage =
    totalPages && currentPage + padding
      ? Math.min(totalPages, currentPage + padding)
      : currentPage + padding;
  const pages = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i,
  );

  const totalDisplayedResults = totalResults ?? endPage * pageSize;

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <p className="text-sm text-gray-700">
        Showing{' '}
        <span className="font-medium">{(currentPage - 1) * pageSize + 1}</span>{' '}
        to{' '}
        <span className="font-medium">
          {Math.min(
            currentPage * pageSize,
            totalResults ?? currentPage * pageSize,
          )}
        </span>{' '}
        of{' '}
        <span className="font-medium">
          {millify(totalDisplayedResults)}
          {!totalResults && '+'}
        </span>{' '}
        results
      </p>
      {endPage - startPage + 1 > 1 && (
        <>
          <div className="flex flex-1 justify-between sm:hidden">
            <button
              onClick={() => !isFirstPage && goToPage(currentPage - 1)}
              className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 ${
                isFirstPage
                  ? 'cursor-not-allowed opacity-50'
                  : 'hover:bg-gray-50'
              }`}
              disabled={isFirstPage}
            >
              Previous
            </button>
            <button
              onClick={() => !isLastPage && goToPage(currentPage + 1)}
              className={`relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 ${
                isLastPage
                  ? 'cursor-not-allowed opacity-50'
                  : 'hover:bg-gray-50'
              }`}
              disabled={isLastPage}
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div className="flex flex-1 justify-end pr-10">
              <nav
                className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                aria-label="Pagination"
              >
                <button
                  onClick={() => !isFirstPage && goToPage(currentPage - 1)}
                  className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 ${
                    isFirstPage
                      ? 'cursor-not-allowed opacity-50'
                      : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                  }`}
                  disabled={isFirstPage}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                {pages.map((page) => (
                  <button
                    key={page}
                    onClick={() => goToPage(page)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                      currentPage === page
                        ? 'z-10 bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                        : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                    }`}
                    aria-current={currentPage === page ? 'page' : undefined}
                  >
                    {page}
                  </button>
                ))}
                <button
                  onClick={() => !isLastPage && goToPage(currentPage + 1)}
                  className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 ${
                    isLastPage
                      ? 'cursor-not-allowed opacity-50'
                      : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                  }`}
                  disabled={isLastPage}
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Pagination;
