import { Transition } from '@headlessui/react';
import { Fragment } from 'react/jsx-runtime';

import ScrollContainer from '@/components/List/ScrollContainer';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { DisplayTemplate } from '../Shared/types';
import TemplateListItem from './TemplateListItem';

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  templates: DisplayTemplate[];
  selectedTemplate: DisplayTemplate | null;
  setSelectedTemplate: (template: DisplayTemplate | null) => void;
}

const TemplateSlideOver: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  templates,
  selectedTemplate,
  setSelectedTemplate,
}: Props) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          // Disable default close behavior
        }}
      >
        <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Template Library
                        </Dialog.Title>
                        <button
                          type="button"
                          className="rounded-md text-gray-400 hover:text-gray-500"
                          onClick={() => {
                            setIsOpen(false);
                          }}
                        >
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <div className="flex-1">
                      <ScrollContainer>
                        <div className="space-y-4 p-4 sm:p-6">
                          {templates.map((template) => (
                            <TemplateListItem
                              key={template.id}
                              template={template}
                              isSelected={selectedTemplate?.id === template.id}
                              onClick={() => setSelectedTemplate(template)}
                            />
                          ))}
                        </div>
                      </ScrollContainer>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default TemplateSlideOver;
