export enum LogoVariant {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum ProfileVariant {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum LogoSize {
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
  XXLARGE = 'xxlarge',
}

export const logoSizeMap = {
  [LogoSize.XSMALL]: {
    logo: 'w-6',
    text: 'text-lg',
  },
  [LogoSize.SMALL]: {
    logo: 'w-6',
    text: 'text-xl',
  },
  [LogoSize.MEDIUM]: {
    logo: 'w-7',
    text: 'text-2xl',
  },
  [LogoSize.LARGE]: {
    logo: 'w-10',
    text: 'text-3xl',
  },
  [LogoSize.XLARGE]: {
    logo: 'w-12',
    text: 'text-4xl',
  },
  [LogoSize.XXLARGE]: {
    logo: 'w-16',
    text: 'text-5xl',
  },
};

export type Messenger = 'user' | 'bot';

export interface ChatMessage {
  id: number;
  sender: Messenger;
  content: string;
}

export interface Jurisdiction {
  country?: string;
  region?: string;
  state?: string;
}

export interface Regulation {
  shortName?: string;
  longName?: string;
  normalizedShortName?: string;
  normalizedLongName?: string;
}

export interface Regulator {
  shortName?: string;
  longName?: string;
  normalizedShortName?: string;
  normalizedLongName?: string;
}

export type Article = {
  url: string;
  contentId: string;
  source: string | null;
  title: string;
  chunks: string[];
  country?: string;
};

export type Citation = {
  contentId?: string;
  sourceId: string;
  quote: string;
};

export type CitedBullet = {
  bullet: string;
  citations: Citation[];
};

export type Summary = {
  bullets: CitedBullet[];
};
