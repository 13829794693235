import { DisplayTemplate } from '../Shared/types';

interface Props {
  template: DisplayTemplate;
  isSelected: boolean;
  onClick: () => void;
}

const TemplateListItem: React.FC<Props> = ({
  template,
  isSelected,
  onClick,
}: Props) => (
  <div
    role="button"
    tabIndex={0}
    className={`cursor-pointer rounded-lg border p-4 transition-all duration-200 ${
      isSelected
        ? 'border-blue-500 bg-blue-50'
        : 'border-gray-200 hover:border-gray-300 hover:bg-gray-50'
    }`}
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick();
      }
    }}
  >
    <div className="flex items-start gap-4">
      <div className="flex-shrink-0">
        <template.icon
          className={`h-6 w-6 ${
            isSelected ? 'text-blue-600' : 'text-gray-400'
          }`}
        />
      </div>
      <div className="flex-1 space-y-1">
        <h4 className="text-sm font-medium text-gray-900">{template.name}</h4>
        <div className="mt-2 flex flex-wrap gap-2">
          {template.regulations.map((reg) => (
            <span
              key={reg}
              className="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
            >
              {reg}
            </span>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default TemplateListItem;
