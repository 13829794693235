// apps/rTech/web/rtech-frontend/src/pages/Auth/ResetPasswordPage.tsx
import backgroundImage from '@/assets/images/blue_spots_bg.png';
import LogoWithText from '@/components/Brand/LogoWithText';
import Button from '@/components/Buttons/Button';
import Spinner from '@/components/Utils/Spinner';
import { navigationMap } from '@/consts/navigation';
import useAuth from '@/hooks/useAuth';
import { LogoSize, LogoVariant } from '@/types';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ErrorDisplay({ message }: { message: string }) {
  return (
    <div
      className={`mt-4 rounded-md bg-red-100 p-4 text-sm text-red-700 transition-opacity duration-300 ${
        message ? 'opacity-100' : 'opacity-0'
      }`}
    >
      {message}
    </div>
  );
}

export default function ResetPasswordPage() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const { confirmResetPassword } = useAuth();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    const res = await confirmResetPassword(email, code, newPassword);
    setLoading(false);

    if (res?.success) {
      toast.success('Password reset successfully, redirecting to login');
      setTimeout(() => {
        navigate(navigationMap.login.href);
      }, 2000);
    }
    if (!res?.success) {
      setError(
        res?.error || 'Failed to reset password, please contact support',
      );
    }
  };

  return (
    <main className="flex min-h-screen">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:w-3/5 lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <LogoWithText variant={LogoVariant.LIGHT} size={LogoSize.MEDIUM} />
            <h2 className="mt-8 text-2xl/9 font-semibold text-slate-900">
              Reset Password
            </h2>
          </div>

          <div className="mt-10">
            <form onSubmit={handleResetPassword} className="space-y-6">
              <p className="mb-4 text-sm text-slate-500">
                Enter your email, the code sent to your email, and your new
                password.
              </p>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mb-4 block w-full rounded-md border-slate-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                required
              />
              <input
                type="text"
                placeholder="Verification Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="mb-4 block w-full rounded-md border-slate-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                required
              />
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="mb-4 block w-full rounded-md border-slate-300 pr-10 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  required
                />
                <span
                  onClick={togglePasswordVisibility}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      togglePasswordVisibility();
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-slate-500"
                  aria-label={showPassword ? 'Hide password' : 'Show password'}
                >
                  {showPassword ? <EyeSlash size={20} /> : <Eye size={20} />}
                </span>
              </div>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="mb-4 block w-full rounded-md border-slate-300 pr-10 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  required
                />
                <span
                  onClick={togglePasswordVisibility}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      togglePasswordVisibility();
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-slate-500"
                  aria-label={showPassword ? 'Hide password' : 'Show password'}
                >
                  {showPassword ? <EyeSlash size={20} /> : <Eye size={20} />}
                </span>
              </div>
              <Button
                type="submit"
                className="flex w-full items-center justify-center rounded-md bg-blue-500 py-2 text-white shadow-sm hover:bg-blue-400"
                disabled={loading}
              >
                {loading ? (
                  <Spinner size="sm" className="text-white" />
                ) : (
                  'Reset Password'
                )}
              </Button>
            </form>

            {error && <ErrorDisplay message={error} />}

            <ToastContainer />
          </div>
        </div>
      </div>
      <div className="relative hidden lg:block lg:w-2/5">
        <img
          alt="Reset password background"
          src={backgroundImage}
          className="absolute inset-0 h-full w-full object-cover"
        />
      </div>
    </main>
  );
}
