import { Aperture } from '@phosphor-icons/react';
import { FC } from 'react';

interface SearchInputProps {
  query: string;
  setQuery: (query: string) => void;
  setOpen: (open: boolean) => void;
}

const SearchInput: FC<SearchInputProps> = ({ query, setQuery, setOpen }) => {
  return (
    <div className="relative flex items-center gap-2">
      <div className="absolute inset-y-0 left-0 flex items-center pl-4">
        <Aperture size={20} weight="regular" className="text-gray-700" />
      </div>
      <input
        type="text"
        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-12 pr-4 text-base text-gray-900 placeholder-gray-500 transition-colors duration-200 hover:bg-blue-50 focus:outline-none"
        placeholder="Ask anything, search regulations..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onClick={() => setOpen(true)}
        aria-label="Search"
      />
    </div>
  );
};

export default SearchInput;
