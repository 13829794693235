import Button from '@/components/Buttons/Button';
import BaseTooltip from '@/components/Utils/BaseTooltip';
import { CitedBullet } from '@/types';
import { ListMagnifyingGlass } from '@phosphor-icons/react';

const MAX_NUM_CITATIONS = 3;

const BulletList = ({
  bullets,
  onSelectSource,
  contentId,
}: {
  bullets: CitedBullet[];
  onSelectSource: (contentId: string | null, sourceId: number) => void;
  contentId: string | null;
}) => (
  <ul className="w-full list-disc pl-4 text-left">
    {bullets.map((bullet, index) => (
      <li key={index} className="w-full py-2 text-left">
        <span className="flex w-full items-start text-left">
          <span className="w-7/8 flex-grow">{bullet.bullet}</span>
          <span className="w-1/8 flex flex-shrink-0">
            {bullet.citations
              .slice(0, MAX_NUM_CITATIONS)
              .map((citation, index) => (
                <Button
                  key={index}
                  variant="text"
                  size="xs"
                  className="mx-0.5 flex-shrink-0 py-0"
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectSource(contentId, Number(citation.sourceId));
                  }}
                >
                  <BaseTooltip
                    key={index}
                    tooltipContent={
                      <div className="max-w-xs">
                        View Citation {citation.sourceId + 1}
                      </div>
                    }
                    side="top"
                    showArrow
                  >
                    <ListMagnifyingGlass
                      className="inline-block h-5 w-5 text-gray-500"
                      size={16}
                    />
                  </BaseTooltip>
                </Button>
              ))}
          </span>
        </span>
      </li>
    ))}
  </ul>
);

const SearchSummary = ({
  bullets,
  onSelectSource,
}: {
  bullets: CitedBullet[];
  onSelectSource: (contentId: string | null, sourceId: number) => void;
}) => {
  console.log('bullets', bullets);
  return (
    <div className="flex w-full flex-col px-8 text-sm text-gray-800">
      <BulletList
        bullets={bullets}
        onSelectSource={onSelectSource}
        contentId={null}
      />
    </div>
  );
};

export default SearchSummary;
