import { ErrorDisplay } from '@/components/Error';
import Pagination from '@/components/List/Pagination';
import NoResultsDisplay from '@/components/NoResults';
import { useSearchContext } from '@/providers/searchProvider';
import { CitedBullet } from '@/types';
import { SearchResult } from '@/types/search';
import { ListMagnifyingGlass, ListPlus, Target } from '@phosphor-icons/react';
import ResultsHeader, { HeaderContainer, TitleSection } from './ResultsHeader';
import SearchResultDetailedView from './SearchResultDetailedView';
import SearchResultList from './SearchResultList';
import SearchSummary from './SearchSummary';

interface Props {
  results: SearchResult[] | null;
  bullets: CitedBullet[] | null;
  selectedResult: SearchResult | null;
  setSelectedResult: (result: SearchResult | null) => void;
  error: Error | null;
  query: string;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  hasSearched: boolean;
}

const NoSearchesDisplay = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-start px-4 pt-[20vh] text-center text-gray-500">
      <ListMagnifyingGlass
        className="mb-4 h-12 w-12 text-gray-400"
        size={48}
        weight="regular"
      />
      <p className="text-lg">
        Ask any question or search regulations above to get started
      </p>
    </div>
  );
};

const SearchResultContainer = ({
  results,
  bullets,
  selectedResult,
  setSelectedResult,
  error,
  query,
  page,
  pageSize,
  setPage,
  hasSearched,
}: Props) => {
  console.log('results container', results);
  const { sortParams, setSortParams } = useSearchContext();

  if (!hasSearched) {
    return <NoSearchesDisplay />;
  }

  if (error) {
    return (
      <ErrorDisplay
        message={"Sorry, we're having trouble fetching your search results"}
      />
    );
  }

  if (!results || results.length === 0) {
    return <NoResultsDisplay />;
  }

  const hasSearchSummary = bullets && bullets.length > 0;

  return (
    <div className="mx-auto flex h-[calc(100vh-50px)] max-h-screen w-full flex-col">
      {selectedResult ? (
        <SearchResultDetailedView />
      ) : (
        <>
          <div className="flex-none">
            <ResultsHeader
              title={
                hasSearchSummary ? 'Your Search Report' : 'Your Search Results'
              }
              Icon={
                Target as React.ComponentType<{
                  size: number;
                  className: string;
                  weight: string;
                }>
              }
              sortParams={sortParams}
              setSortParams={setSortParams}
              setSelectedResult={setSelectedResult}
            />
            {hasSearchSummary && (
              <>
                <SearchSummary
                  bullets={bullets}
                  onSelectSource={(contentId, sourceId) => {
                    console.log('contentId', contentId);
                    console.log('sourceId', sourceId);
                  }}
                />
                <HeaderContainer>
                  <TitleSection
                    title="Your Search Results"
                    Icon={
                      ListPlus as React.ComponentType<{
                        size: number;
                        className: string;
                        weight: string;
                      }>
                    }
                  />
                </HeaderContainer>
              </>
            )}
          </div>

          <div className="min-h-0 flex-1 overflow-auto">
            <SearchResultList
              searchResults={results}
              selectedSearchResult={selectedResult}
              setSelectedSearchResult={setSelectedResult}
            />
          </div>

          {results && results.length > 0 && !selectedResult && (
            <div className="flex-none border-t">
              <div className="mx-auto max-w-7xl pb-3">
                <Pagination
                  currentPage={page}
                  pageSize={pageSize}
                  padding={2}
                  goToPage={setPage}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SearchResultContainer;
