import Button from '@/components/Buttons/Button';
import ScrollContainer from '@/components/List/ScrollContainer';
import Modal from '@/components/Modals/Modal';
import { PROFILE_SECTIONS } from '@/components/Profiles/Shared/constants';
import ProfileActions from '@/components/Profiles/Shared/ProfileActions';
import ProfileAvatar from '@/components/Profiles/Shared/ProfileAvatar';
import ProfileSection from '@/components/Profiles/Shared/ProfileSection';
import ProfileSectionList from '@/components/Profiles/Shared/ProfileSectionList';
import { countryCodeToName } from '@/lib/utils';
import { Profile } from '@/types/profiles';
import { GlobeAltIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

interface ProfileViewProps {
  profile: Profile;
  onClose: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

const ProfileView: React.FC<ProfileViewProps> = ({
  profile,
  onClose,
  onEdit,
  onDelete,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <ScrollContainer>
      <div className="p-6">
        <div className="mb-6 flex items-center justify-between">
          <div className="flex items-center">
            <ProfileAvatar name={profile.name} className="mr-4" size="base" />
            <h2 className="text-xl font-semibold text-gray-900">
              {profile.name}
            </h2>
          </div>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-gray-500"
          >
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {PROFILE_SECTIONS.map((section) => (
          <ProfileSection
            key={section.type}
            icon={section.icon}
            title={section.title}
          >
            <ProfileSectionList
              items={profile[section.type]}
              variant="readonly"
            />
          </ProfileSection>
        ))}

        <ProfileSection icon={GlobeAltIcon} title="Jurisdictions">
          <ProfileSectionList
            items={profile.jurisdictions
              .map((j) => j.country)
              .filter((country): country is string => country !== undefined)
              .map(countryCodeToName)
              .filter((name): name is string => !!name)}
            variant="readonly"
          />
        </ProfileSection>

        <div className="mt-6">
          <ProfileActions
            showEdit
            showDelete
            onEdit={onEdit}
            onDelete={() => setDeleteModalOpen(true)}
          />
        </div>
      </div>

      <Modal open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <div className="flex flex-col gap-4">
          <h2 className="text-lg font-medium">Delete Profile</h2>
          <p className="text-sm text-gray-500">
            Are you sure you want to delete this profile?
          </p>
          <div className="flex justify-end gap-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" size="sm" onClick={onDelete}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </ScrollContainer>
  );
};

export default ProfileView;
