import { ErrorDisplay } from '@/components/Error';
import SideBarFilters from '@/components/Filters/SideBarFilters';
import Pagination from '@/components/List/Pagination';
import ScrollContainer from '@/components/List/ScrollContainer';
import NotificationList from '@/components/Notification/NotificationList';
import ResultsHeader from '@/components/Notification/ResultsHeader';
import Spinner from '@/components/Utils/Spinner';
import { navigationMap } from '@/consts/navigation';
import usePageVisitTracker from '@/hooks/tracking/usePageVisitTracker';
import useFilters from '@/hooks/useFilters';
import { useNotificationFilters } from '@/hooks/useNotificationFilters';
import { useNotifications } from '@/hooks/useNotifications';
import NavLayout from '@/layouts/Navigation/NavLayout';
import { parseFilterOption } from '@/lib/utils';
import { useProfilesContext } from '@/providers/profilesProvider';
import { SortParams } from '@/types/api';
import { FilterEnum } from '@/types/filter';
import {
  NotificationResult,
  SortFieldNotification,
} from '@/types/notification';
import { Transition } from '@headlessui/react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import urlJoin from 'url-join';

export default function RadarPage() {
  usePageVisitTracker('Radar');
  const navigate = useNavigate();
  const [sortParams, setSortParams] = useState<
    SortParams<SortFieldNotification>
  >({
    field: 'date',
    order: 'desc',
  });
  const [endDate, setEndDate] = useState<Date>(
    new Date(new Date().setHours(23, 59, 59, 999)),
  );
  const [startDate, setStartDate] = useState<Date>(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setFullYear(date.getFullYear() - 1);
    return date;
  });
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const { profiles, isFetching: isFetchingProfiles } = useProfilesContext();
  const { filters, setFilters } = useNotificationFilters({
    profileIds: profiles?.map((p) => p.profileId) || [],
  });
  const { handleSelect, handleDeselect, handleSelectAll, handleDeselectAll } =
    useFilters({ setFilters });
  const [selectedProfileIds, setSelectedProfileIds] = useState<string[]>([]);
  const { notificationId } = useParams();
  const [selectedNotification, setSelectedNotificationInner] =
    useState<NotificationResult | null>(null);

  useEffect(() => {
    if (profiles) {
      setSelectedProfileIds(profiles.map((p) => p.profileId));
    }
  }, [profiles]);

  const {
    isFetchingNotifications,
    notificationsError: error,
    notifications,
    totalPages,
    totalCount,
    notificationByIdData,
  } = useNotifications({
    page,
    pageSize,
    profileIds: selectedProfileIds,
    jurisdiction: filters[FilterEnum.JURISDICTION]
      ?.filter((option) => option.checked)
      ?.map((option) =>
        parseFilterOption(option, (value) => JSON.parse(value)),
      ),
    regulator: filters[FilterEnum.AUTHORITY]
      ?.filter((option) => option.checked)
      ?.map((option) =>
        parseFilterOption(option, (value) => JSON.parse(value)),
      ),
    regulation: filters[FilterEnum.REGULATION]
      ?.filter((option) => option.checked)
      ?.map((option) =>
        parseFilterOption(option, (value) => JSON.parse(value)),
      ),
    documentType: filters[FilterEnum.DOCUMENT_TYPE]
      ?.filter((option) => option.checked)
      ?.map((option) => option.value),
    topic: filters[FilterEnum.TOPIC]
      ?.filter((option) => option.checked)
      ?.map((option) => option.value),
    startDate,
    endDate,
    sortParams,
    searchFilter: query,
    notificationId: selectedNotification ? undefined : notificationId,
  });

  const setSelectedNotification = useCallback(
    (notification: NotificationResult | null) => {
      setSelectedNotificationInner(notification);
      if (!notification) {
        navigate(navigationMap.radar.href);
      } else {
        navigate(urlJoin(navigationMap.radar.href, notification.id));
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (notificationId && notificationByIdData) {
      setSelectedNotification(notificationByIdData);
    }
  }, [notificationId, notificationByIdData]);

  const showLoadingSpinner = isFetchingNotifications && !selectedNotification;

  return (
    <NavLayout>
      <ToastContainer />
      <div className="flex h-full w-full flex-col">
        <div className="flex w-full flex-1 overflow-hidden">
          <div className="w-[15%] flex-1 overflow-y-auto py-1 pr-1">
            <ScrollContainer>
              <SideBarFilters
                filters={filters}
                handleSelect={handleSelect}
                handleDeselect={handleDeselect}
                handleSelectAll={handleSelectAll}
                handleDeselectAll={handleDeselectAll}
              />
            </ScrollContainer>
          </div>
          <div className="flex w-[85%] flex-col overflow-y-auto py-1 pl-1">
            <ResultsHeader
              profiles={profiles || []}
              selectedProfileIds={selectedProfileIds}
              setSelectedProfileIds={setSelectedProfileIds}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              sortParams={sortParams}
              setSortParams={setSortParams}
              setSelectedNotification={setSelectedNotification}
            />
            <div className="relative flex-1">
              <Transition
                show={showLoadingSpinner}
                enter="transition ease-out duration-500"
                enterFrom="translate-y-full opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition ease-in duration-500"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="translate-y-full opacity-0"
                className="absolute inset-0 flex items-center justify-center"
              >
                <Spinner size="sm" />
              </Transition>
              <Transition
                show={!showLoadingSpinner}
                enter="transition ease-out duration-500"
                enterFrom="translate-y-full opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition ease-in duration-500"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="translate-y-full opacity-0"
                className="absolute inset-0"
              >
                {error ? (
                  <ErrorDisplay
                    message={
                      "Sorry, we're having trouble fetching your notifications."
                    }
                  />
                ) : (
                  <NotificationList
                    notifications={notifications || []}
                    selectedNotification={selectedNotification}
                    setSelectedNotification={setSelectedNotification}
                  />
                )}
              </Transition>
            </div>
            {notifications &&
              notifications?.length > 0 &&
              !selectedNotification &&
              totalCount && (
                <div className="mt-4">
                  <Pagination
                    totalPages={totalPages}
                    currentPage={page}
                    pageSize={pageSize}
                    totalResults={totalCount}
                    padding={2}
                    goToPage={setPage}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    </NavLayout>
  );
}
