import {
  getSearchFilterOptions,
  GetSearchFilterOptionsResponse,
} from '@/api/search';
import { countryCodeToName } from '@/lib/utils';
import { FilterEnum, FilterKey, FilterOption } from '@/types/filter';
import {
  QueryObserverResult,
  RefetchOptions,
  useQuery,
} from '@tanstack/react-query';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

const SEARCH_FILTER_OPTIONS_QUERY_KEY = 'SEARCH_FILTER_OPTIONS';
const ONE_HOUR_IN_MS = 1000 * 60 * 60;

interface SearchFiltersContextType {
  filters: Record<FilterKey, FilterOption[]>;
  setFilters: Dispatch<SetStateAction<Record<FilterKey, FilterOption[]>>>;
  filterError: Error | null;
  isFetchingFilters: boolean;
  refetchFilters: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<GetSearchFilterOptionsResponse, Error>>;
}

const SearchFiltersContext = createContext<SearchFiltersContextType>({
  filters: {} as Record<FilterKey, FilterOption[]>,
  setFilters: () => {
    console.log('setFilters');
  },
  filterError: null,
  isFetchingFilters: true,
  refetchFilters: async (
    options?: RefetchOptions,
  ): Promise<QueryObserverResult<GetSearchFilterOptionsResponse, Error>> => {
    throw new Error('SearchFiltersContext not initialized');
  },
});

export function SearchFiltersProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [filters, setFilters] = useState<Record<FilterKey, FilterOption[]>>(
    {} as Record<FilterKey, FilterOption[]>,
  );
  const {
    data: filterOptions,
    error: filterError,
    isFetching: isFetchingFilters,
    refetch: refetchFilters,
  } = useQuery<GetSearchFilterOptionsResponse, Error>({
    queryKey: [SEARCH_FILTER_OPTIONS_QUERY_KEY],
    queryFn: () => getSearchFilterOptions(),
    staleTime: ONE_HOUR_IN_MS,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const value: SearchFiltersContextType = {
    filters,
    setFilters,
    filterError: filterError,
    isFetchingFilters,
    refetchFilters,
  };

  useEffect(() => {
    const formattedFilters = formatFilters(filterOptions);
    setFilters(formattedFilters);
  }, [filterOptions]);

  return (
    <SearchFiltersContext.Provider value={value}>
      {children}
    </SearchFiltersContext.Provider>
  );
}

function formatFilters(
  filters: GetSearchFilterOptionsResponse | undefined,
): Record<FilterKey, FilterOption[]> {
  if (!filters) {
    return {
      [FilterEnum.DOCUMENT_TYPE]: [],
      [FilterEnum.JURISDICTION]: [],
      [FilterEnum.AUTHORITY]: [],
      [FilterEnum.REGULATION]: [],
      [FilterEnum.TOPIC]: [],
    };
  }

  return {
    [FilterEnum.JURISDICTION]:
      filters?.jurisdictions
        ?.filter((jurisdiction) => jurisdiction.country)
        ?.map((jurisdiction) => ({
          value: JSON.stringify(jurisdiction),
          label:
            countryCodeToName(jurisdiction?.country as string) ||
            (jurisdiction.country as string),
          defaultChecked: false,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    [FilterEnum.AUTHORITY]:
      filters.regulators
        ?.filter((regulator) => regulator.longName)
        ?.map((regulator) => ({
          value: JSON.stringify(regulator),
          label: regulator.longName as string,
          defaultChecked: false,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    [FilterEnum.REGULATION]:
      filters.regulations
        ?.filter((regulation) => regulation.longName)
        ?.map((regulation) => ({
          value: JSON.stringify(regulation),
          label: regulation.longName as string,
          defaultChecked: false,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    [FilterEnum.DOCUMENT_TYPE]:
      filters.documentTypes
        ?.map((type) => ({
          value: type,
          label: type,
          defaultChecked: false,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    [FilterEnum.TOPIC]: [],
  };
}

// Update the hook to use the context
export function useSearchFiltersContext() {
  return useContext(SearchFiltersContext);
}
