import {
  mockSearchContent,
  mockSearchFilters,
  mockSearchResponse,
  mockStream,
  mockSummarize,
} from '@/__mocks__/search';
import {
  CitedBullet,
  Jurisdiction,
  Regulation,
  Regulator,
  Summary,
} from '@/types';
import { SortParams } from '@/types/api';
import { SearchResult } from '@/types/search';
import api from './index';
import streamApi, { streamAsyncIterator } from './streamApi';

export type SortField = 'date_published' | 'date_enforced' | 'relevance';

export type SearchResponse = {
  articles: SearchResult[];
  filters: {
    source: string[];
    documentType: string[];
    dateAfter: Date | null;
    dateBefore: Date | null;
    company: string[];
    topic: string[];
    regulation: Regulation[];
    regulator: Regulator[];
    jurisdiction: Jurisdiction[];
    sortParams?: SortParams<SortField>;
  };
  stage: string;
  bullets?: CitedBullet[];
};

export interface QueryParams {
  query: string;
  pageNumber: number;
  pageSize: number;
  abortSignal?: AbortSignal | undefined;
  summarize?: boolean;
  filters?: {
    source?: string[];
    documentType?: string[];
    dateAfter?: Date | null;
    dateBefore?: Date | null;
    company?: string[];
    topic?: string[];
    regulation?: Regulation[];
    regulator?: Regulator[];
    jurisdiction?: Jurisdiction[];
    sortParams?: SortParams<SortField>;
  };
}

export interface SummarizeParams {
  query: string;
  contentId: string;
  chunkIds: string[];
}

export interface SummarizeStreamParams {
  summaries: Summary[];
}

export interface GetSearchFilterOptionsResponse {
  documentTypes: string[];
  sources: string[];
  topics: string[];
  regulators: Regulator[];
  regulations: Regulation[];
  jurisdictions: Jurisdiction[];
}

const BASE_URL = '/query';

export const summarizeSearch = async (
  params: SummarizeParams,
): Promise<Summary> => {
  console.log('summarizeSearch called with params:', params);
  if (process.env.NODE_ENV === 'development') {
    return await mockSummarize();
  }
  try {
    const response = await api.post<Summary>(BASE_URL + '/summarize', params);
    console.log('summarizeSearch response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error summarizing:', error);
    throw error;
  }
};

export const querySearchStream = async (
  params: QueryParams,
  signal: AbortSignal | undefined,
): Promise<AsyncGenerator<SearchResponse, void, unknown>> => {
  console.log('querySearchStream called with params:', params);
  if (process.env.NODE_ENV === 'development') {
    return mockStream();
  }
  try {
    const response = await streamApi.post(BASE_URL, params, {
      signal,
      params: {
        page_number: params.pageNumber,
        page_size: params.pageSize,
      },
    });

    if (!response.body) {
      console.log('querySearchStream response has no body:', response);
      throw new Error('No response body');
    }
    console.log('querySearchStream response body:', response.body);
    return streamAsyncIterator(response.body);
  } catch (error) {
    console.error('Error in querySearchStream:', error);
    throw error;
  }
};

export const querySearch = async (
  params: QueryParams,
): Promise<SearchResponse> => {
  console.log('querySearch called with params:', params);
  if (process.env.NODE_ENV === 'development') {
    return await mockSearchResponse();
  }
  try {
    const response = await api.post<SearchResponse>(BASE_URL, params, {
      signal: params.abortSignal,
      params: {
        page_number: params.pageNumber,
        page_size: params.pageSize,
      },
    });
    console.log('querySearch response:', response.data);
    return response.data;
  } catch (error) {
    if (error instanceof Error && error.name === 'CanceledError') {
      console.log('Search request was aborted');
    } else {
      console.error('Error searching articles:', error);
    }
    throw error;
  }
};

export const getSearchFilterOptions =
  async (): Promise<GetSearchFilterOptionsResponse> => {
    console.log('getSearchFilterOptions called');
    if (process.env.NODE_ENV === 'development') {
      return await mockSearchFilters();
    }
    try {
      const response =
        await api.get<GetSearchFilterOptionsResponse>('/query/filters');
      console.log('getSearchFilterOptions response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error getting search filter options:', error);
      throw error;
    }
  };

export const getSearchContent = async (
  article: SearchResult,
): Promise<string[]> => {
  console.log('getSearchContent called for article:', article);
  if (process.env.NODE_ENV === 'development') {
    return await mockSearchContent();
  }
  try {
    const response = await api.get<string[]>(
      `/query/content/${article.contentId}`,
    );
    console.log('getSearchContent response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error getting search content:', error);
    throw error;
  }
};
