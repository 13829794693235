import { deepCamelCaseTransform, deepSnakeCaseTransform } from '@/api';
import { handleGetSession } from '@/hooks/useAuth';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const partialFetch = (method: string) => {
  return async (url: string, data: any = {}, args: any = {}) => {
    const session = await handleGetSession();
    const token = session?.tokens?.idToken;
    const headers = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      ...args.headers,
    });

    const queryParams = new URLSearchParams(args.params).toString();
    const fullUrl = `${BASE_URL}${url}${queryParams ? `?${queryParams}` : ''}`;

    data = deepSnakeCaseTransform(data);
    const config = {
      method,
      headers,
      body: JSON.stringify(data),
      ...args,
    };
    return fetch(fullUrl, config);
  };
};

export async function* streamAsyncIterator(stream: ReadableStream) {
  const reader = stream.getReader();
  const decoder = new TextDecoder();
  try {
    let jsonBuilder = '';
    while (true) {
      const { done, value } = await reader.read();
      if (done) return;
      if (value) {
        const decodedValue = decoder.decode(value);
        jsonBuilder += decodedValue;

        if (
          jsonBuilder.includes('$$JSON$$') &&
          jsonBuilder.includes('$$END$$')
        ) {
          const jsonParts = jsonBuilder.split('$$JSON$$');
          for (let i = 1; i < jsonParts.length; i++) {
            const endIndex = jsonParts[i].indexOf('$$END$$');
            if (endIndex !== -1) {
              const jsonString = jsonParts[i].substring(0, endIndex);
              try {
                const jval = JSON.parse(jsonString);
                const camelJval = deepCamelCaseTransform(jval);
                yield camelJval;
              } catch (error) {
                console.error('Error parsing JSON:', error);
              }
              jsonBuilder = jsonParts[i].substring(endIndex + 7); // Remove processed part
            }
          }
        } else if (!jsonBuilder.includes('$$JSON$$')) {
          yield decodedValue;
          jsonBuilder = '';
        }
      }
    }
  } finally {
    reader.releaseLock();
  }
}

const streamApi = {
  get: partialFetch('GET'),
  post: partialFetch('POST'),
  put: partialFetch('PUT'),
  delete: partialFetch('DELETE'),
} as const;

export default streamApi;
