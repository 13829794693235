import SideBarFilters from '@/components/Filters/SideBarFilters';
import SearchResultContainer from '@/components/Search/SearchResults/SearchResultContainer';
import { SearchResultsContainerSkeleton } from '@/components/Search/Skeletons';
import usePageVisitTracker from '@/hooks/tracking/usePageVisitTracker';
import useFilters from '@/hooks/useFilters';
import NavLayout from '@/layouts/Navigation/NavLayout';
import { useSearchFiltersContext } from '@/providers/searchFiltersProvider';
import { useSearchContext } from '@/providers/searchProvider';
import { Transition } from '@headlessui/react';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

export default function SearchPage() {
  usePageVisitTracker('Search');
  const { filters, setFilters } = useSearchFiltersContext();
  const { selectedResult, setSelectedResult, hasSearched } =
    useSearchContext();
  const { handleSelect, handleDeselect, handleSelectAll, handleDeselectAll } =
    useFilters({ setFilters });
  const {
    results,
    summaryBullets,
    isLoading,
    error,
    pageNumber,
    pageSize,
    setPageNumber,
  } = useSearchContext();

  useEffect(() => {
    console.log(
      'results search page',
      results,
      summaryBullets,
      isLoading,
      error,
    );
  }, [results, summaryBullets, isLoading, error]);

  return (
    <NavLayout>
      <ToastContainer />
      <div className="flex h-full w-full flex-col">
        <div className="h-full flex-1 overflow-hidden">
          <div className="flex h-full flex-1 overflow-hidden pt-1">
            <div className="w-[15%]">
              <SideBarFilters
                filters={filters}
                handleSelectAll={handleSelectAll}
                handleDeselectAll={handleDeselectAll}
                handleSelect={handleSelect}
                handleDeselect={handleDeselect}
              />
            </div>
            <div className="flex w-[85%]">
              <div className="relative mx-auto max-w-7xl flex-1 justify-center">
                <Transition
                  show={isLoading}
                  enter="transition-opacity duration-150"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="flex h-full w-full justify-center"
                >
                  <SearchResultsContainerSkeleton />
                </Transition>
                <Transition
                  show={!isLoading}
                  enter="transition-opacity duration-150"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute inset-0"
                >
                  <SearchResultContainer
                    results={results}
                    bullets={summaryBullets?.bullets ?? []}
                    selectedResult={selectedResult}
                    setSelectedResult={setSelectedResult}
                    error={error}
                    query={''}
                    page={pageNumber}
                    pageSize={pageSize}
                    setPage={setPageNumber}
                    hasSearched={hasSearched}
                  />
                </Transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NavLayout>
  );
}
