import { BriefcaseIcon } from '@heroicons/react/24/outline';

import { ScaleIcon } from '@heroicons/react/24/outline';
import { DisplayTemplate } from '../Shared/types';

interface TagListProps {
  icon: React.ElementType;
  items: string[];
  total: number;
}

const TagList: React.FC<TagListProps> = ({
  icon: Icon,
  items,
  total,
}: TagListProps) => (
  <div className="flex items-center text-sm text-gray-600">
    <Icon className="mr-2 h-4 w-4" />
    <span className="truncate">
      {items.join(', ')}
      {total > items.length && ` +${total - items.length}`}
    </span>
  </div>
);

interface Props {
  template: DisplayTemplate;
  onClick: () => void;
}

const TemplateCard: React.FC<Props> = ({ template, onClick }: Props) => (
  <button
    type="button"
    tabIndex={0}
    className="group relative cursor-pointer overflow-hidden rounded-xl border border-gray-200 bg-white px-3 py-4 shadow-sm transition-all duration-200 hover:border-gray-300 hover:shadow-md"
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }}
  >
    <div className="mb-3">
      <template.icon className="h-6 w-6 text-blue-600" />
    </div>
    <h3 className="text-md mb-2 truncate text-left font-medium text-gray-900">
      {template.name}
    </h3>
    <div className="space-y-1.5">
      <TagList
        icon={ScaleIcon}
        items={template.regulations.slice(0, 2)}
        total={template.regulations.length}
      />
      <TagList
        icon={BriefcaseIcon}
        items={template.services.slice(0, 2)}
        total={template.services.length}
      />
    </div>
  </button>
);

export default TemplateCard;
