import { COUNTRY_CODES_TO_NAMES } from '@/consts/geo';
import { FilterOption } from '@/types/filter';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Converts a country code to a country name.
 * @param code - The country code (e.g., "US").
 * @returns The country name or undefined if the code is not found.
 */
export function countryCodeToName(code: string): string | undefined {
  return COUNTRY_CODES_TO_NAMES[code];
}

/**
 * Converts a country name to a country code.
 * @param name - The country name (e.g., "United States").
 * @returns The country code or undefined if the name is not found.
 */
export function nameToCountryCode(name: string): string | undefined {
  const entry = Object.entries(COUNTRY_CODES_TO_NAMES).find(
    ([, value]) => value === name,
  );
  return entry ? entry[0] : undefined;
}

export function parseFilterOption<T>(
  option: FilterOption,
  parseValue: (value: string) => T,
): T {
  return parseValue(option.value);
}
