import Pill from '@/components/Buttons/Pill';
import ShortDateTime from '@/components/DateTime/ShortDateTime';
import { SearchResult } from '@/types/search';
import { BuildingLibraryIcon, ScaleIcon } from '@heroicons/react/16/solid';
import { CalendarBlank, FileText, ListDashes } from '@phosphor-icons/react';
import { useState } from 'react';

const NUM_TOPICS = 5;

function SearchResultContent({
  searchResult,
  setSourceId,
  selectedSearchResult,
  setSelectedSearchResult,
  view,
}: {
  searchResult: SearchResult;
  setSourceId: (sourceId: number) => void;
  selectedSearchResult: SearchResult | null;
  setSelectedSearchResult: (searchResult: SearchResult | null) => void;
  view: 'narrow' | 'wide';
}) {
  const isNarrowView = view === 'narrow';

  const truncateText = (text: string, maxLength: number) =>
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  const firstRegulator = searchResult.regulators?.[0];
  const firstRegulation = searchResult.regulations?.[0];

  return (
    <button
      className={`relative flex w-full flex-col items-start px-4 py-2 hover:bg-gray-50`}
      onClick={() => {
        if (searchResult === selectedSearchResult) {
          setSelectedSearchResult(null);
          return;
        }
        setSelectedSearchResult(searchResult);
      }}
    >
      {/* Top row with regulators and document type */}
      <div className="mb-2 flex w-full items-center justify-between">
        <div className="flex gap-2">
          {firstRegulator && (
            <Pill
              variant="outline"
              size="xs"
              color="gray"
              text={truncateText(
                firstRegulator?.longName || firstRegulator?.shortName || '',
                50,
              )}
              startIcon={<BuildingLibraryIcon className="h-3.5 w-3.5" />}
            />
          )}
          {firstRegulation && (
            <Pill
              variant="outline"
              size="xs"
              color="gray"
              text={truncateText(
                firstRegulation.longName || firstRegulation.shortName || '',
                50,
              )}
              startIcon={<ScaleIcon className="h-3.5 w-3.5" />}
            />
          )}
          {searchResult.documentType && (
            <Pill
              variant="outline"
              size="xs"
              color="gray"
              text={searchResult.documentType}
              startIcon={<FileText className="h-3.5 w-3.5" weight="fill" />}
            />
          )}
        </div>
        {/* Date with icon */}
        {searchResult.datePublished && (
          <div className="mb-2 flex items-center gap-1 text-sm text-gray-500">
            <CalendarBlank className="h-3.5 w-3.5" />
            <ShortDateTime dateTime={searchResult.datePublished} />
          </div>
        )}
      </div>

      {/* Title */}
      <div className="mb-2 text-sm font-medium text-gray-900">
        {searchResult.title}
      </div>

      {/* Topics list */}
      {searchResult.topics && searchResult.topics.length > 0 && (
        <div className="flex items-start gap-2 text-sm text-gray-600">
          <ListDashes className="mt-0.5 h-4 w-4 shrink-0" />
          <div className="flex flex-wrap gap-2">
            {searchResult.topics.slice(0, NUM_TOPICS).map((topic, index) => (
              <>
                {index > 0 && <span className="text-gray-300">|</span>}
                <span key={topic}>{truncateText(topic, 50)}</span>
              </>
            ))}
          </div>
        </div>
      )}
    </button>
  );
}

export default function SearchResultItem({
  searchResult,
  selectedSearchResult,
  setSelectedSearchResult,
  setSourceId,
  view,
}: {
  searchResult: SearchResult;
  selectedSearchResult: SearchResult | null;
  setSelectedSearchResult: (searchResult: SearchResult | null) => void;
  setSourceId: (sourceId: number) => void;
  view: 'narrow' | 'wide';
}) {
  const [isOpen, setIsOpen] = useState(
    selectedSearchResult?.contentId === searchResult.contentId,
  );

  return (
    <div className="w-full">
      <SearchResultContent
        searchResult={searchResult}
        setSourceId={setSourceId}
        selectedSearchResult={selectedSearchResult}
        setSelectedSearchResult={setSelectedSearchResult}
        view={view}
      />
    </div>
  );
}
