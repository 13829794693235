// src/components/Profiles/shared/ProfileActions.tsx
import Button from '@/components/Buttons/Button';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';

interface ActionButton {
  label: string;
  onClick: () => void;
  icon?: React.ComponentType<{ className?: string }>;
  variant?: 'primary' | 'outline' | 'danger';
  disabled?: boolean;
  loading?: boolean;
}

interface Props {
  primaryAction?: ActionButton;
  secondaryAction?: ActionButton;
  showEdit?: boolean;
  showDelete?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  className?: string;
}

const ProfileActions: React.FC<Props> = ({
  primaryAction,
  secondaryAction,
  showEdit,
  showDelete,
  onEdit,
  onDelete,
  className = '',
}: Props) => {
  return (
    <div className={`flex items-center justify-end gap-2 ${className}`}>
      {secondaryAction && (
        <Button
          variant="outline"
          size="sm"
          onClick={secondaryAction.onClick}
          disabled={secondaryAction.disabled}
          icon={secondaryAction.icon}
        >
          {secondaryAction.label}
        </Button>
      )}

      {showEdit && (
        <Button variant="outline" size="md" icon={PencilIcon} onClick={onEdit}>
          Edit Profile
        </Button>
      )}

      {showDelete && (
        <Button variant="danger" size="md" icon={TrashIcon} onClick={onDelete}>
          Delete Profile
        </Button>
      )}

      {primaryAction && (
        <Button
          variant={primaryAction.variant || 'primary'}
          size="sm"
          onClick={primaryAction.onClick}
          disabled={primaryAction.disabled}
          icon={primaryAction.icon}
        >
          {primaryAction.loading ? 'Loading...' : primaryAction.label}
        </Button>
      )}
    </div>
  );
};

export default ProfileActions;
