// src/components/Profiles/shared/constants.ts
import {
  BookOpenIcon,
  BriefcaseIcon,
  ScaleIcon,
} from '@heroicons/react/24/outline';
import { ProfileSectionConfig } from './types';

export const PROFILE_SECTIONS: ProfileSectionConfig[] = [
  {
    type: 'regulations',
    icon: ScaleIcon,
    title: 'Regulations',
    description:
      'Add specific regulations you want to stay on top of, e.g. PSD2, GDPR, or AMLD6',
  },
  {
    type: 'services',
    icon: BriefcaseIcon,
    title: 'Services',
    description:
      'Add services or products that you want to track related regulatory changes for e.g. payments, lending, or digital assets',
  },
  {
    type: 'complianceTopics',
    icon: BookOpenIcon,
    title: 'Compliance Topics',
    description:
      'Add relevant compliance topics you want to track e.g. Anti-Money Laundering, Data Protection, or Anti-Bribery',
  },
];

export const MAX_PROFILE_NAME_LENGTH = 50;
