import { PROFILE_TEMPLATES } from '@/consts/profiles';
import { Dialog, Transition } from '@headlessui/react';
import {
  BriefcaseIcon,
  BuildingLibraryIcon,
  ChartBarIcon,
  CreditCardIcon,
  CubeIcon,
  ScaleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, { Fragment, useState } from 'react';
import Button from '../../Buttons/Button';
import { DisplayTemplate } from '../Shared/types';
import TemplateCard from './TemplateCard';
import TemplateSlideOver from './TemplateSlideOver';

const TEMPLATE_ICON_MAP: Record<DisplayTemplate['id'], React.ElementType> = {
  crypto: CubeIcon,
  payments: CreditCardIcon,
  banking: BuildingLibraryIcon,
  investment: ChartBarIcon,
  lending: BuildingLibraryIcon,
};

const templates = PROFILE_TEMPLATES.map((template) => ({
  ...template,
  icon: TEMPLATE_ICON_MAP[template.id],
}));

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onApply: (template: DisplayTemplate) => void;
}

const TemplateDetailView: React.FC<{
  template: DisplayTemplate | null;
  onClose: () => void;
  onApply: (template: DisplayTemplate) => void;
}> = ({ template, onClose, onApply }) => {
  if (!template) return null;

  return (
    <Transition.Root show={!!template} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        open={!!template}
        onClose={onClose}
        static
      >
        <div
          role="button"
          className="fixed inset-0 bg-gray-500/75 transition-opacity"
        />

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Dialog.Panel
              className="relative w-full max-w-4xl rounded-xl bg-white p-6 shadow-xl"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Close button */}
              <button
                onClick={onClose}
                className="absolute right-4 top-4 rounded-full p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>

              {/* Header */}
              <div className="mb-8">
                <div className="flex items-center gap-4">
                  <template.icon className="h-12 w-12 text-blue-600" />
                  <div>
                    <h2 className="text-2xl font-semibold text-gray-900">
                      {template.name}
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      Complete profile template for{' '}
                      {template.name.toLowerCase()} businesses
                    </p>
                  </div>
                </div>
              </div>

              {/* Content */}
              <div className="grid gap-8 lg:grid-cols-2">
                {/* Left column */}
                <div className="space-y-8">
                  {/* Regulations Section */}
                  <section>
                    <h3 className="flex items-center gap-2 text-lg font-medium text-gray-900">
                      <ScaleIcon className="h-5 w-5 text-blue-600" />
                      Regulations
                    </h3>
                    <div className="mt-4 space-y-3">
                      {template.regulations.map((reg) => (
                        <div
                          key={reg}
                          className="flex items-center rounded-lg border border-gray-200 bg-white p-4 shadow-sm"
                        >
                          <span className="text-sm text-gray-700">{reg}</span>
                        </div>
                      ))}
                    </div>
                  </section>

                  {/* Services Section */}
                  <section>
                    <h3 className="flex items-center gap-2 text-lg font-medium text-gray-900">
                      <BriefcaseIcon className="h-5 w-5 text-blue-600" />
                      Services
                    </h3>
                    <div className="mt-4 space-y-3">
                      {template.services.map((service) => (
                        <div
                          key={service}
                          className="flex items-center rounded-lg border border-gray-200 bg-white p-4 shadow-sm"
                        >
                          <span className="text-sm text-gray-700">
                            {service}
                          </span>
                        </div>
                      ))}
                    </div>
                  </section>
                </div>

                {/* Right column */}
                <div className="space-y-8">
                  {/* Summary Stats */}
                  <div className="rounded-xl border border-gray-200 bg-gray-50 p-6">
                    <dl className="grid grid-cols-2 gap-6">
                      <div>
                        <dt className="text-sm font-medium text-gray-500">
                          Regulations
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold text-blue-600">
                          {template.regulations.length}
                        </dd>
                      </div>
                      <div>
                        <dt className="text-sm font-medium text-gray-500">
                          Services
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold text-blue-600">
                          {template.services.length}
                        </dd>
                      </div>
                    </dl>
                  </div>

                  {/* Action Card */}
                  <div className="rounded-xl border border-gray-200 bg-white p-6">
                    <h4 className="text-lg font-medium text-gray-900">
                      Ready to get started?
                    </h4>
                    <p className="mt-2 text-sm text-gray-500">
                      Apply this template to your profile and customize it to
                      match your specific needs.
                    </p>
                    <div className="mt-6">
                      <Button
                        variant="primary"
                        size="lg"
                        className="w-full"
                        onClick={() => {
                          onApply(template);
                          onClose();
                        }}
                      >
                        Apply Template
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const ProfileTemplates: React.FC<Props> = ({ isOpen, setIsOpen, onApply }) => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<DisplayTemplate | null>(null);

  return (
    <div className="mt-8 w-full">
      <div className="mb-6">
        <h3 className="text-md font-medium text-gray-900">
          Popular Templates
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Get started quickly with pre-configured templates (optional)
        </p>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        {templates.slice(0, 4).map((template) => (
          <TemplateCard
            key={template.id}
            template={template}
            onClick={() => {
              setSelectedTemplate(template);
            }}
          />
        ))}
      </div>

      <div className="mt-6 flex w-full justify-center">
        <Button
          variant="text"
          size="md"
          onClick={() => setIsOpen(true)}
          className="w-[200px] hover:bg-gray-50"
        >
          View All Templates
        </Button>
      </div>

      <TemplateSlideOver
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        templates={templates}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={(template) => {
          setSelectedTemplate(template);
        }}
      />

      <TemplateDetailView
        template={selectedTemplate}
        onClose={() => {
          setSelectedTemplate(null);
        }}
        onApply={(template) => {
          onApply(template);
          setSelectedTemplate(null);
          setIsOpen(false);
        }}
      />
    </div>
  );
};

export default ProfileTemplates;
