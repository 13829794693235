import ProfileFilter from '@/components/Filters/ProfileFilter';
import DateRangePicker from '@/components/Menus/DateRangePicker';
import SelectDropdownMenu from '@/components/Menus/SelectDropdownMenu';
import { SortOrder, SortParams } from '@/types/api';
import { SortOption } from '@/types/filter';
import {
  NotificationResult,
  SortFieldNotification,
} from '@/types/notification';
import { Profile } from '@/types/profiles';
import { Menu } from '@headlessui/react';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CalendarIcon,
  ChartBarIcon,
} from '@heroicons/react/20/solid';
import { Bell } from '@phosphor-icons/react';

interface Props {
  profiles: Profile[];
  selectedProfileIds: string[];
  setSelectedProfileIds: (ids: string[]) => void;
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  sortParams: SortParams<SortFieldNotification>;
  setSortParams: (sortParams: SortParams<SortFieldNotification>) => void;
  setSelectedNotification: (notification: NotificationResult | null) => void;
}

const ResultsHeader = ({
  profiles,
  selectedProfileIds,
  setSelectedProfileIds,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  sortParams,
  setSortParams,
  setSelectedNotification,
}: Props) => {
  const sortOptions: SortOption<SortFieldNotification, SortOrder>[] = [
    {
      field: 'date',
      order: 'desc',
      label: 'Date',
      description: 'Date: Newest first',
      icon: CalendarIcon,
    },
    {
      field: 'date',
      order: 'asc',
      label: 'Date',
      description: 'Date: Oldest first',
      icon: CalendarIcon,
    },
    {
      field: 'risk',
      order: 'desc',
      label: 'Risk',
      description: 'Risk: Highest first',
      icon: ChartBarIcon,
    },
    {
      field: 'risk',
      order: 'asc',
      label: 'Risk',
      description: 'Risk: Lowest first',
      icon: ChartBarIcon,
    },
  ];

  return (
    <div className="flex h-14 w-full items-center justify-between px-4 py-3 text-sm text-gray-900">
      <div className="flex items-center gap-x-1">
        <Bell size={20} className="text-gray-900/80" weight="bold" />
        <div className="text-base font-medium text-gray-900">
          Your Notifications
        </div>
      </div>
      <div className="relative inline-block flex items-center gap-x-3 px-8 text-left text-sm font-medium">
        <ProfileFilter
          profiles={profiles || []}
          selectedProfileIds={selectedProfileIds}
          onSelectionChange={setSelectedProfileIds}
        />
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={(date) => {
            setSelectedNotification(null);
            setStartDate(new Date(date));
          }}
          setEndDate={(date) => {
            setSelectedNotification(null);
            setEndDate(new Date(date));
          }}
          inputClassName="w-[280px] text-sm text-gray-700 rounded-full bg-gray-100 pl-10 pr-3 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors"
        />
        <SelectDropdownMenu
          label={
            <div className="flex items-center gap-x-2">
              {sortOptions.map((option) =>
                option.field === sortParams.field &&
                option.order === sortParams.order ? (
                  <div
                    key={`${option.field}-${option.order}`}
                    className="flex items-center gap-x-1"
                  >
                    <span className="text-gray-700">{option.label}</span>
                    {option.order === 'desc' ? (
                      <ArrowDownIcon className="h-4 w-4 text-gray-700" />
                    ) : (
                      <ArrowUpIcon className="h-4 w-4 text-gray-700" />
                    )}
                  </div>
                ) : null,
              )}
            </div>
          }
          buttonClassName="rounded-full bg-gray-100 px-4 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors"
        >
          {sortOptions.map((option) => (
            <Menu.Item key={`${option.field}-${option.order}`}>
              {({ active }) => (
                <button
                  onClick={() => {
                    setSelectedNotification(null);
                    setSortParams({
                      field: option.field,
                      order: option.order,
                    });
                  }}
                  className={`${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  } ${
                    sortParams.field === option.field &&
                    sortParams.order === option.order
                      ? 'bg-gray-50'
                      : ''
                  } group flex w-full items-center justify-between px-4 py-2 text-sm`}
                >
                  <div className="flex items-center">
                    {option.icon && (
                      <option.icon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    )}
                    {option.description}
                  </div>
                  {option.order === 'desc' ? (
                    <ArrowDownIcon className="ml-2 h-5 w-5 text-gray-400" />
                  ) : (
                    <ArrowUpIcon className="ml-2 h-5 w-5 text-gray-400" />
                  )}
                </button>
              )}
            </Menu.Item>
          ))}
        </SelectDropdownMenu>
      </div>
    </div>
  );
};

export default ResultsHeader;
