import CreateProfileView from '@/components/Profiles/Create/CreateProfileView';
import ProfileListItem from '@/components/Profiles/ProfileListItem';
import ProfileView from '@/components/Profiles/ProfileView';
import { useProfiles } from '@/hooks/useProfiles';
import { useProfilesContext } from '@/providers/profilesProvider';
import { useUserContext } from '@/providers/userProvider';
import { Profile } from '@/types/profiles';
import { PlusIcon, UserIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useState } from 'react';
import Button from '../Buttons/Button';
import ScrollContainer from '../List/ScrollContainer';

interface Props {
  profiles: Profile[];
}

const ProfileHome: React.FC<Props> = ({ profiles }: Props) => {
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  const { user } = useUserContext();
  const { deleteProfile } = useProfiles(user?.userId);
  const { refetchProfiles } = useProfilesContext();

  const handleCreate = () => {
    setSelectedProfile(null);
    setIsCreating(true);
  };

  const handleEdit = (profile: Profile) => {
    setSelectedProfile(profile);
    setIsCreating(true);
  };

  const handleView = (profile: Profile) => {
    setSelectedProfile(profile);
    setIsCreating(false);
  };

  const handleClose = useCallback(() => {
    setSelectedProfile(null);
    setIsCreating(false);
  }, []);

  const handleDelete = useCallback(
    async (profile: Profile) => {
      await deleteProfile({ profileId: profile.profileId });
      await refetchProfiles();
      handleClose();
    },
    [deleteProfile, refetchProfiles, handleClose],
  );

  return (
    <div className="mx-auto h-full w-full px-4 sm:px-6 lg:px-8">
      <div className="mx-auto h-full max-w-7xl py-4">
        <div className="h-full space-y-6">
          {!selectedProfile && !isCreating && (
            <div className="flex h-full flex-col transition-opacity duration-200 ease-in-out">
              <div className="mb-4 flex items-end justify-between">
                <h2 className="text-lg font-semibold text-gray-900">
                  Trackers
                </h2>
                <Button
                  variant="primary"
                  size="md"
                  icon={PlusIcon}
                  onClick={handleCreate}
                >
                  Create
                </Button>
              </div>
              <hr className="mb-4 border-t border-gray-200" />
              {profiles.length === 0 ? (
                <NoProfiles onCreateProfile={handleCreate} />
              ) : (
                <div className="flex-1 overflow-hidden">
                  <ScrollContainer>
                    <div className="flex flex-col gap-3">
                      {profiles.map((profile, idx) => (
                        <React.Fragment key={profile.profileId}>
                          <ProfileListItem
                            profile={profile}
                            onEdit={() => handleEdit(profile)}
                            onClick={() => handleView(profile)}
                            isSelected={selectedProfile === profile}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  </ScrollContainer>
                </div>
              )}
            </div>
          )}

          {(selectedProfile || isCreating) && (
            <div className="relative h-full transition-opacity duration-200 ease-in-out">
              {isCreating ? (
                <CreateProfileView
                  onClose={handleClose}
                  profile={selectedProfile ?? undefined}
                />
              ) : (
                selectedProfile && (
                  <ProfileView
                    profile={selectedProfile}
                    onClose={handleClose}
                    onEdit={() => handleEdit(selectedProfile)}
                    onDelete={() => handleDelete(selectedProfile)}
                  />
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const NoProfiles = ({ onCreateProfile }: { onCreateProfile: () => void }) => (
  <div className="flex h-full w-full flex-col items-center justify-center px-4 py-16 text-center text-gray-500">
    <UserIcon className="mb-4 h-12 w-12 text-gray-400" />
    <p className="text-base font-medium">
      {"We couldn't find any trackers for you"}
    </p>
    <p className="mt-2 text-sm">
      <button
        onClick={onCreateProfile}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onCreateProfile();
          }
        }}
        className="cursor-pointer text-blue-500 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Create a new tracker
      </button>{' '}
      to get started
    </p>
  </div>
);

export default ProfileHome;
