import { FC } from 'react';

interface SearchItemProps {
  name: string;
  onClick?: () => void;
}

const SearchItem: FC<SearchItemProps> = ({ name, onClick }) => {
  return (
    <li className="w-full">
      <button
        onClick={onClick}
        className="flex w-full items-center rounded-md px-4 py-2 text-left text-gray-800 transition-colors duration-200 hover:bg-blue-500 hover:text-white focus:outline-none"
      >
        <span className="flex-1 text-sm font-medium capitalize">{name}</span>
      </button>
    </li>
  );
};

export default SearchItem;
