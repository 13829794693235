import { SortField } from '@/api/search';
import SelectDropdownMenu from '@/components/Menus/SelectDropdownMenu';
import { SortOrder, SortParams } from '@/types/api';
import { SortOption } from '@/types/filter';
import { SearchResult } from '@/types/search';
import { Menu } from '@headlessui/react';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Bars3Icon,
  CalendarIcon,
} from '@heroicons/react/20/solid';

interface Props {
  title: string;
  Icon: React.ComponentType<{
    size: number;
    className: string;
    weight: string;
  }>;
  sortParams: SortParams<SortField>;
  setSortParams: (sortParams: SortParams<SortField>) => void;
  setSelectedResult: (result: SearchResult | null) => void;
}

export const TitleSection = ({
  title,
  Icon,
}: {
  title: string;
  Icon: React.ComponentType<{
    size: number;
    className: string;
    weight: string;
  }>;
}) => {
  return (
    <div className="flex items-center gap-x-1">
      <Icon size={20} className="text-gray-900/80" weight="bold" />
      <div className="text-base font-medium text-gray-900">{title}</div>
    </div>
  );
};

export const HeaderContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className="flex h-14 w-full items-center justify-between px-4 py-3 text-sm text-gray-900">
      {children}
    </div>
  );
};

const ResultsHeader = ({
  title,
  Icon,
  sortParams,
  setSortParams,
  setSelectedResult,
}: Props) => {
  const sortOptions: SortOption<SortField, SortOrder>[] = [
    {
      field: 'relevance',
      order: 'desc',
      label: 'Relevance',
      description: 'Relevance: Highest first',
      icon: Bars3Icon,
    },
    {
      field: 'date_published',
      order: 'desc',
      label: 'Date',
      description: 'Date: Newest first',
      icon: CalendarIcon,
    },
    {
      field: 'date_published',
      order: 'asc',
      label: 'Date',
      description: 'Date: Oldest first',
      icon: CalendarIcon,
    },
  ];

  return (
    <HeaderContainer>
      <TitleSection title={title} Icon={Icon} />
      <div className="relative inline-block flex items-center gap-x-3 px-8 text-left text-sm font-medium">
        <SelectDropdownMenu
          label={
            <div className="flex items-center gap-x-2">
              {sortOptions.map((option) =>
                option.field === sortParams?.field &&
                option.order === sortParams?.order ? (
                  <div
                    key={`${option.field}-${option.order}`}
                    className="flex items-center gap-x-1"
                  >
                    <span className="text-gray-700">{option.label}</span>
                    {option.order === 'desc' ? (
                      <ArrowDownIcon className="h-4 w-4 text-gray-700" />
                    ) : (
                      <ArrowUpIcon className="h-4 w-4 text-gray-700" />
                    )}
                  </div>
                ) : null,
              )}
            </div>
          }
          buttonClassName="rounded-full bg-gray-100 px-4 py-2 focus:ring-0 border-0 cursor-pointer hover:bg-gray-200 transition-colors"
        >
          {sortOptions.map((option) => (
            <Menu.Item key={`${option.field}-${option.order}`}>
              {({ active }) => (
                <button
                  onClick={() => {
                    setSelectedResult(null);
                    setSortParams({
                      field: option.field,
                      order: option.order,
                    });
                  }}
                  className={`${
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  } ${
                    sortParams?.field === option.field &&
                    sortParams?.order === option.order
                      ? 'bg-gray-50'
                      : ''
                  } group flex w-full items-center justify-between px-4 py-2 text-sm`}
                >
                  <div className="flex items-center">
                    {option.icon && (
                      <option.icon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    )}
                    {option.description}
                  </div>
                  {option.order === 'desc' ? (
                    <ArrowDownIcon className="ml-2 h-5 w-5 text-gray-400" />
                  ) : (
                    <ArrowUpIcon className="ml-2 h-5 w-5 text-gray-400" />
                  )}
                </button>
              )}
            </Menu.Item>
          ))}
        </SelectDropdownMenu>
      </div>
    </HeaderContainer>
  );
};

export default ResultsHeader;
