import ContentLoader from 'react-content-loader';

export const RagSourceSkeleton = (props: any) => (
  <ContentLoader
    speed={1.5}
    height={120}
    className="py-3"
    backgroundColor="#e3e3e3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="140" height="12" />
    <rect x="0" y="20" rx="3" ry="3" width="160" height="12" />
    <rect x="0" y="64" rx="3" ry="3" width="90" height="8" />
    <rect x="0" y="84" rx="3" ry="3" width="110" height="8" />
  </ContentLoader>
);

export const RagSummarySkeleton = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={480}
    className="mt-3 p-1"
    backgroundColor="#d2d2d2"
    foregroundColor="#ecebeb"
    preserveAspectRatio="none"
    {...props}
  >
    <rect x="0" y="20" rx="3" ry="3" width="95%" height="8" />
    <rect x="0" y="40" rx="3" ry="3" width="98%" height="8" />
    <rect x="0" y="60" rx="3" ry="3" width="92%" height="8" />
    <rect x="0" y="80" rx="3" ry="3" width="85%" height="8" />
    <rect x="0" y="100" rx="3" ry="3" width="90%" height="8" />
    <rect x="0" y="120" rx="3" ry="3" width="70%" height="8" />
    <rect x="0" y="180" rx="3" ry="3" width="80%" height="8" />
    <rect x="0" y="200" rx="3" ry="3" width="85%" height="8" />
    <rect x="0" y="240" rx="3" ry="3" width="75%" height="8" />
    <rect x="0" y="260" rx="3" ry="3" width="80%" height="8" />
    <rect x="0" y="300" rx="3" ry="3" width="85%" height="8" />
    <rect x="0" y="340" rx="3" ry="3" width="88%" height="8" />
    <rect x="0" y="360" rx="3" ry="3" width="80%" height="8" />
    <rect x="0" y="400" rx="3" ry="3" width="85%" height="8" />
    <rect x="0" y="420" rx="3" ry="3" width="70%" height="8" />
    <rect x="0" y="440" rx="3" ry="3" width="75%" height="8" />
  </ContentLoader>
);

export const RagSummaryBulletsSkeleton = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={240}
    className="mt-3 p-1"
    backgroundColor="#d2d2d2"
    foregroundColor="#ecebeb"
    preserveAspectRatio="none"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="95%" height="8" />
    <rect x="0" y="20" rx="3" ry="3" width="98%" height="8" />
    <rect x="0" y="60" rx="3" ry="3" width="85%" height="8" />
    <rect x="0" y="80" rx="3" ry="3" width="95%" height="8" />
    <rect x="0" y="100" rx="3" ry="3" width="90%" height="8" />
    <rect x="0" y="120" rx="3" ry="3" width="92%" height="8" />
    <rect x="0" y="160" rx="3" ry="3" width="95%" height="8" />
    <rect x="0" y="180" rx="3" ry="3" width="98%" height="8" />
    <rect x="0" y="200" rx="3" ry="3" width="75%" height="8" />
    <rect x="0" y="220" rx="3" ry="3" width="85%" height="8" />
  </ContentLoader>
);

export const ArticleContentSkeleton = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={600}
    className="mt-3 p-1"
    backgroundColor="#d2d2d2"
    foregroundColor="#ecebeb"
    preserveAspectRatio="none"
    {...props}
  >
    {[...Array(30)].map((_, index) => (
      <rect
        key={index}
        x="0"
        y={`${index * 20}`}
        rx="3"
        ry="3"
        width={`${Math.floor(Math.random() * 30) + 70}%`}
        height="8"
      />
    ))}
  </ContentLoader>
);

export const SearchResultItemSkeleton = (props: any) => (
  <ContentLoader
    speed={2}
    width="100%"
    height={120}
    backgroundColor="#d2d2d2"
    foregroundColor="#ecebeb"
    preserveAspectRatio="none"
    {...props}
  >
    {/* Top row - Pills */}
    <rect x="16" y="8" rx="12" ry="12" width="120" height="24" />
    <rect x="146" y="8" rx="12" ry="12" width="140" height="24" />
    <rect x="296" y="8" rx="12" ry="12" width="100" height="24" />
    {/* Date (right-aligned) */}
    <rect x="calc(100% - 96px)" y="12" rx="3" ry="3" width="80" height="16" />
    {/* Title */}
    <rect x="16" y="48" rx="3" ry="3" width="90%" height="16" />
    {/* Topics */}
    <rect x="16" y="80" rx="3" ry="3" width="16" height="16" /> {/* Icon */}
    <rect x="40" y="84" rx="3" ry="3" width="25%" height="8" />
    <rect x="calc(25% + 50px)" y="84" rx="3" ry="3" width="25%" height="8" />
    <rect x="calc(50% + 60px)" y="84" rx="3" ry="3" width="25%" height="8" />
  </ContentLoader>
);

export const SearchResultsContainerSkeleton = (props: any) => (
  <div className="mx-auto flex w-full flex-col overflow-hidden">
    <div className="mb-4 w-full flex-none">
      {/* Header skeleton */}
      <ContentLoader
        speed={2}
        width="100%"
        height={60}
        backgroundColor="#d2d2d2"
        foregroundColor="#ecebeb"
        preserveAspectRatio="none"
        {...props}
      >
        <rect x="16" y="16" rx="3" ry="3" width="200" height="24" />
      </ContentLoader>
    </div>

    <div className="w-full flex-1 space-y-4">
      {/* Render multiple SearchResultItemSkeletons */}
      {[...Array(10)].map((_, index) => (
        <div key={index} className="w-full border-b pb-4">
          <SearchResultItemSkeleton />
        </div>
      ))}
    </div>
  </div>
);
