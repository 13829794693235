import { ProfileTemplate } from '@/types/profiles';

export const PROFILE_TEMPLATES: ProfileTemplate[] = [
  {
    complianceTopics: [
      'AML',
      'KYC',
      'Transaction Monitoring',
      'Virtual Asset Compliance',
      'Tax Reporting',
      'Blockchain Transparency',
    ],
    regulations: [
      'FATF Recommendations',
      'EU AMLD6',
      'FinCEN Requirements',
      'MiCA',
      'FATCA',
      'Travel Rule',
    ],
    services: [
      'Cryptocurrency Exchange',
      'Custody Wallet Services',
      'Virtual Asset Service Provider',
      'Crypto Tax Tools',
    ],
    name: 'Crypto Exchange and VASP Compliance',
    id: 'crypto',
  },
  {
    complianceTopics: [
      'Data Privacy',
      'Cross-Border Transactions',
      'Payment Fraud',
      'Remittance Rules',
      'Currency Exchange Reporting',
      'Fraud Prevention',
      'Payment Compliance',
    ],
    regulations: [
      'GDPR',
      'PSD2',
      'PCI DSS',
      'Money Transfer Services',
      'Foreign Exchange',
      'Payment Services',
    ],
    services: ['Payment Gateway', 'Merchant Services'],
    name: 'Payments Provider Compliance',
    id: 'payments',
  },
  {
    complianceTopics: [
      'Lending Practices',
      'Credit Scoring Transparency',
      'Fair Lending Laws',
    ],
    regulations: ['ECOA', 'FCRA', 'CFPB Guidelines'],
    services: ['Consumer Lending', 'Loan Origination'],
    name: 'Lending Institution Compliance',
    id: 'lending',
  },
  {
    complianceTopics: [
      'Investment Advisory Rules',
      'Portfolio Risk Management',
      'Client Suitability',
    ],
    regulations: ['MiFID II', 'SEC IA Act', 'FINRA Rules'],
    services: ['Asset Management', 'Wealth Advisory'],
    name: 'Investment Firm Compliance',
    id: 'investment',
  },
  {
    complianceTopics: [
      'Anti-Money Laundering',
      'Sanctions Screening',
      'Regulatory Reporting',
    ],
    regulations: ['BSA', 'FATF', 'OFAC Guidelines'],
    services: ['Banking', 'Trade Finance'],
    name: 'Bank Compliance',
    id: 'banking',
  },
  {
    complianceTopics: [
      'Fund Administration',
      'Investor Reporting',
      'Risk Management',
    ],
    regulations: ['AIFMD', 'SEC Investment Company Act', 'ESMA Guidelines'],
    services: ['Fund Management', 'Investment Fund Administration'],
    name: 'Investment Fund Compliance',
    id: 'investment',
  },
];
