import {
  getFilterOptions,
  GetFilterOptionsResponse,
} from '@/api/notifications';
import { countryCodeToName } from '@/lib/utils';
import { FilterEnum, FilterKey, FilterOption } from '@/types/filter';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

const FILTER_OPTIONS_QUERY_KEY = 'FILTER_OPTIONS';

export function useNotificationFilters({
  profileIds,
}: {
  profileIds: string[];
}) {
  const [filters, setFilters] = useState<Record<FilterKey, FilterOption[]>>(
    {} as Record<FilterKey, FilterOption[]>,
  );
  const {
    data: filterOptions,
    error: filterError,
    isFetching: isFetchingFilters,
  } = useQuery<GetFilterOptionsResponse>({
    queryKey: [FILTER_OPTIONS_QUERY_KEY, profileIds],
    queryFn: () => getFilterOptions(profileIds),
    // enabled: !!profileIds.length,
    refetchOnWindowFocus: false,
  });

  console.log('filterOptions', filterOptions);

  const formatFilters = (
    filters: GetFilterOptionsResponse | undefined,
  ): Record<FilterKey, FilterOption[]> => {
    if (!filters) {
      return {
        [FilterEnum.JURISDICTION]: [],
        [FilterEnum.AUTHORITY]: [],
        [FilterEnum.REGULATION]: [],
        [FilterEnum.DOCUMENT_TYPE]: [],
        [FilterEnum.TOPIC]: [],
      };
    }

    return {
      [FilterEnum.JURISDICTION]:
        filters?.jurisdictions
          ?.filter((jurisdiction) => jurisdiction.country)
          ?.map((jurisdiction) => ({
            value: JSON.stringify(jurisdiction),
            label:
              countryCodeToName(jurisdiction?.country as string) ||
              (jurisdiction.country as string),
            defaultChecked: false,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) || [],
      [FilterEnum.AUTHORITY]:
        filters.regulators
          ?.filter((regulator) => regulator.longName)
          ?.map((regulator) => ({
            value: JSON.stringify(regulator),
            label: regulator.longName as string,
            defaultChecked: false,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) || [],
      [FilterEnum.REGULATION]:
        filters.regulations
          ?.filter((regulation) => regulation.longName)
          ?.map((regulation) => ({
            value: JSON.stringify(regulation),
            label: regulation.longName as string,
            defaultChecked: false,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) || [],
      [FilterEnum.DOCUMENT_TYPE]:
        filters.documentTypes
          ?.map((type) => ({
            value: type,
            label: type,
            defaultChecked: false,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) || [],
      [FilterEnum.TOPIC]: [],
    };
  };

  useEffect(() => {
    const formattedFilters = formatFilters(filterOptions);
    setFilters(formattedFilters);
  }, [filterOptions]);

  return {
    filters,
    setFilters,
    filterError,
    isFetchingFilters,
  };
}
