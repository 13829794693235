import { QueryParams, SearchResponse, SortField } from '@/api/search';
import { useSearch, useSearchSummarize } from '@/hooks/useSearch';
import { parseFilterOption } from '@/lib/utils';
import { CitedBullet, Summary } from '@/types';
import { SortParams } from '@/types/api';
import { FilterEnum } from '@/types/filter';
import { SearchResult } from '@/types/search';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useSearchFiltersContext } from './searchFiltersProvider';

interface SummaryBullets {
  bullets: CitedBullet[];
}

interface SearchContextType {
  // Methods from useSearch
  performQuery: (
    params: Omit<QueryParams, 'abortSignal'>,
  ) => Promise<SearchResponse>;
  fetchArticleContent: (article: SearchResult) => Promise<string[] | null>;
  // State from useSearch
  query: string;
  setQuery: (query: string) => void;
  results: SearchResult[] | null;
  summaryBullets: SummaryBullets | null;
  error: Error | null;
  isLoading: boolean;
  pageNumber: number;
  pageSize: number;
  hasSearched: boolean;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  sortParams: SortParams<SortField>;
  setSortParams: (sortParams: SortParams<SortField>) => void;
  selectedResult: SearchResult | null;
  setSelectedResult: (result: SearchResult | null) => void;
  articleContents: Map<string, string[]>;
  loadingArticleContents: Set<string>;
  // Methods from useSearchSummarize
  performSummarization: (
    article: SearchResult,
    query: string,
  ) => Promise<Summary | undefined>;
  // State from useSearchSummarize
  summarizeResults: Summary;
  summarizeError: Error | null;
  isSummarizeLoading: boolean;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const SearchProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const search = useSearch();
  const summarize = useSearchSummarize();
  const navigate = useNavigate();
  const location = useLocation();
  const { filters } = useSearchFiltersContext();
  const [debouncedQuery] = useDebounce(search.query, 300);
  const [debouncedFilters] = useDebounce(filters, 300);
  const prevQueryRef = useRef<string | undefined>();
  const prevFiltersRef = useRef(debouncedFilters);
  const prevSortParamsRef = useRef(search.sortParams);
  const hasActiveFilters = useMemo(() => {
    return Object.values(debouncedFilters).some((filter) =>
      filter.some((option) => option.checked),
    );
  }, [debouncedFilters]);
  const [hasSearched, setHasSearched] = useState(false);

  // Ensure that search.setSelectedResult and search.fetchArticleContent are stable
  const handleSetSelectedResult = useCallback(
    async (result: SearchResult | null) => {
      search.setSelectedResult(result);
      if (result) {
        console.log('fetching article content for', result);
        try {
          await search.fetchArticleContent(result);
        } catch (error) {
          console.error('Failed to fetch article content:', error);
        }
      }
    },
    [search.setSelectedResult, search.fetchArticleContent],
  );

  const performQueryWithNavigation = useCallback(
    async (params: Omit<QueryParams, 'abortSignal'>) => {
      if (location.pathname !== '/search') {
        navigate('/search');
      }
      return search.performQuery(params);
    },
    [location.pathname, navigate, search.performQuery],
  );

  useEffect(() => {
    const queryChanged = prevQueryRef.current !== debouncedQuery;
    const sortParamsChanged =
      prevSortParamsRef.current.field !== search.sortParams.field ||
      prevSortParamsRef.current.order !== search.sortParams.order;
    const filtersChanged =
      JSON.stringify(prevFiltersRef.current) !== JSON.stringify(filters);

    if (queryChanged || filtersChanged || sortParamsChanged) {
      // Reset page number to 1 if query or filters have changed
      search.setPageNumber(1);
    }

    if (!hasActiveFilters && !debouncedQuery) {
      // If there are no active filters and no query, do nothing
      return;
    }

    // Update previous values
    prevQueryRef.current = debouncedQuery;
    prevFiltersRef.current = debouncedFilters;
    prevSortParamsRef.current = search.sortParams;
    setHasSearched(true);

    // Perform the query with the current page number
    performQueryWithNavigation({
      query: debouncedQuery,
      pageNumber: search.pageNumber, // Use the current page number
      pageSize: search.pageSize,
      summarize: search.pageNumber === 1,
      filters: {
        jurisdiction: filters[FilterEnum.JURISDICTION]
          ?.filter((option) => option.checked)
          ?.map((option) =>
            parseFilterOption(option, (value) => JSON.parse(value)),
          ),
        regulator: filters[FilterEnum.AUTHORITY]
          ?.filter((option) => option.checked)
          ?.map((option) =>
            parseFilterOption(option, (value) => JSON.parse(value)),
          ),
        regulation: filters[FilterEnum.REGULATION]
          ?.filter((option) => option.checked)
          ?.map((option) =>
            parseFilterOption(option, (value) => JSON.parse(value)),
          ),
        documentType: filters[FilterEnum.DOCUMENT_TYPE]
          ?.filter((option) => option.checked)
          ?.map((option) => parseFilterOption(option, (value) => value)),
        topic: filters[FilterEnum.TOPIC]
          ?.filter((option) => option.checked)
          ?.map((option) => parseFilterOption(option, (value) => value)),
        dateAfter: null,
        dateBefore: null,
        sortParams: search.sortParams,
      },
    });

    search.setSelectedResult(null);
  }, [
    debouncedFilters,
    debouncedQuery,
    search.pageNumber,
    search.pageSize,
    search.sortParams,
  ]);

  return (
    <SearchContext.Provider
      value={{
        performQuery: performQueryWithNavigation,
        fetchArticleContent: search.fetchArticleContent,
        query: search.query,
        setQuery: search.setQuery,
        results: search.results,
        summaryBullets: search.summaryBullets,
        error: search.error,
        isLoading: search.isLoading,
        pageNumber: search.pageNumber,
        pageSize: search.pageSize,
        hasSearched: hasSearched,
        setPageNumber: search.setPageNumber,
        setPageSize: search.setPageSize,
        sortParams: search.sortParams,
        setSortParams: search.setSortParams,
        selectedResult: search.selectedResult,
        setSelectedResult: handleSetSelectedResult,
        articleContents: search.articleContents,
        loadingArticleContents: search.loadingArticleContents,
        performSummarization: summarize.performSummarization,
        summarizeResults: summarize.summarizeResults,
        summarizeError: summarize.summarizeError,
        isSummarizeLoading: summarize.isSummarizeLoading,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = (): SearchContextType => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
};
