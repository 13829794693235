// src/components/Profiles/shared/ProfileSectionList.tsx
import Pill from '@/components/Buttons/Pill';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface Props {
  items: string[];
  onRemoveItem?: (index: number) => void;
  variant?: 'editable' | 'readonly';
}

const ProfileSectionList: React.FC<Props> = ({
  items,
  onRemoveItem,
  variant = 'readonly',
}: Props) => {
  if (items.length === 0) return null;

  return (
    <Transition
      show={items.some(Boolean)}
      enter="transition-all duration-300 ease-out"
      enterFrom="transform -translate-y-4 opacity-0"
      enterTo="transform translate-y-0 opacity-100"
      leave="transition-all duration-200 ease-in"
      leaveFrom="transform translate-y-0 opacity-100"
      leaveTo="transform -translate-y-4 opacity-0"
    >
      <div className="flex flex-wrap gap-2">
        {items.filter(Boolean).map((item, idx) => (
          <Pill
            key={idx}
            variant="solid"
            size="sm"
            startIcon={
              variant === 'editable' ? (
                <XMarkIcon className="h-4 w-4" />
              ) : undefined
            }
            text={item}
            onClick={
              variant === 'editable' ? () => onRemoveItem?.(idx) : undefined
            }
          />
        ))}
      </div>
    </Transition>
  );
};

export default ProfileSectionList;
