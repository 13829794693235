import { Profile } from '@/types/profiles';
import { PROFILE_SECTIONS } from './Shared/constants';
import ProfileAvatar from './Shared/ProfileAvatar';
import ProfileSectionList from './Shared/ProfileSectionList';

interface Props {
  profile: Profile;
  onClick: () => void;
  onEdit: () => void;
  isSelected: boolean;
}

const ProfileListItem: React.FC<Props> = ({
  profile,
  onClick,
  onEdit,
  isSelected,
}: Props) => {
  return (
    <div
      className={`group relative w-full cursor-pointer rounded-lg border px-4 py-4 transition-all duration-200 ease-in-out ${
        isSelected
          ? 'border-blue-200 bg-blue-50/50'
          : 'border-gray-200 hover:border-gray-300 hover:bg-gray-50/50'
      }`}
      onClick={onClick}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
      role="button"
      tabIndex={0}
    >
      <div className="flex items-start gap-4">
        <ProfileAvatar name={profile.name} size="base" />
        <div className="min-w-0 flex-1">
          <h3 className="truncate text-sm font-medium text-gray-900">
            {profile.name}
          </h3>
          <div className="mt-4 flex flex-wrap gap-x-6 gap-y-3">
            {PROFILE_SECTIONS.map((section) => (
              <div key={section.type} className="flex items-center gap-2">
                <section.icon className="h-4 w-4 text-gray-500" />
                <ProfileSectionList
                  items={profile[section.type].slice(0, 3)}
                  variant="readonly"
                />
                {profile[section.type].length > 3 && (
                  <span className="text-xs text-gray-500">
                    +{profile[section.type].length - 3}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileListItem;
