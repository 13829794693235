export type FilterOption = {
  value: string;
  label: string;
  checked?: boolean;
  defaultChecked?: boolean;
};

export type Filter = {
  id: FilterKey;
  name: string;
  options: FilterOption[];
  defaultOpen?: boolean;
};

export type FilterKey =
  | FilterEnum.JURISDICTION
  | FilterEnum.AUTHORITY
  | FilterEnum.REGULATION
  | FilterEnum.DOCUMENT_TYPE
  | FilterEnum.TOPIC;

export enum FilterEnum {
  JURISDICTION = 'jurisdiction',
  AUTHORITY = 'authority',
  REGULATION = 'regulation',
  DOCUMENT_TYPE = 'documentType',
  TOPIC = 'topic',
}

export const FilterDisplayName: Record<FilterKey, string> = {
  [FilterEnum.JURISDICTION]: 'Country',
  [FilterEnum.AUTHORITY]: 'Authority',
  [FilterEnum.REGULATION]: 'Regulation',
  [FilterEnum.DOCUMENT_TYPE]: 'Document Type',
  [FilterEnum.TOPIC]: 'Topic',
};

export interface SortOption<TField, TOrder> {
  field: TField;
  order: TOrder;
  label: string;
  description: string;
  icon: React.ForwardRefExoticComponent<any>;
}
